const EXTENSIONS = {
  FXT2: "fxt2",
  FXT3: "fxt3",
  FXT3P: "fxt3p",
  STEP: "stp",
  STL: "stl",
  GLB: "glb",
  GLB_DRACO: "draco.glb",
  SVG: "svg",
  DXF: "dxf",
  JSON: "json",
  PNG: "png",
  JPG: "jpg",
  JPEG: "jpeg",
  WEBP: "webp",
  BMP: "bmp",
  MAP: "map",
  ZIP: "zip",
};

Object.freeze(EXTENSIONS);

export default EXTENSIONS;

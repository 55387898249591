import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

export const NavSide = ({ links }) => {
  const linksEl = [];
  links.forEach((link) => {
    const { title, to } = link;
    linksEl.push(
      <li key={to}>
        <NavLink
          to={to}
          className={({ isActive }) => (isActive ? "nav-link-active" : "")}
        >
          {title}
        </NavLink>
      </li>
    );
  });

  return (
    <nav className="menu menu-vertical">
      <ul>{linksEl}</ul>
    </nav>
  );
};

NavSide.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

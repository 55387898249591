const SPLAY_STARTS = {
  NO: "NO",
  BACK: "BACK",
  MID: "MID",
  FRONT: "FRONT",
};

Object.freeze(SPLAY_STARTS);

export default SPLAY_STARTS;

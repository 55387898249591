const S3_REGION = "eu-west-1";
const S3_BUCKET = "frameup-assets-prod";
const SERVER = {
  PORT: 8080,
  PORT_TOOLS: 8081,
  S3_REGION,
  S3_BUCKET,
  JSON_LIMIT: "10mb",
  ASSETS_URL: "https://assets.frameup.ai",
};

Object.freeze(SERVER);

export default SERVER;

import { getDotString } from "@utils/common";
import React from "react";

export const FieldError = ({ name, errors, touched: toucheds }) => {
  const error = errors ? getDotString(errors, name) : null;
  const touched = toucheds ? getDotString(toucheds, name) : null;
  if (touched && error) {
    return <div className="label-error">{touched && error}</div>;
  }
  return <></>;
};

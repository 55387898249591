import { useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";

const fetchLocale = async ({ locale, defaultLocale, directory }) => {
  if (_.isEmpty(locale)) {
    throw new Error("Missing parameter locale");
  }
  if (_.isEmpty(directory)) {
    throw new Error("Missing parameter directory");
  }

  try {
    const request = await axios.get(`${directory}/${locale}.json`);
    return { messages: request.data, locale };
  } catch (error) {
    console.error(
      new Error(
        `Couldn't fetch locale. Will fetch default locale (${defaultLocale})`
      )
    );
    const request = await axios.get(`${directory}/${defaultLocale}.json`);
    return { messages: request.data, locale: defaultLocale };
  }
};

const useLocale = ({ directory, overrideLocale, overrideDefaultLocale }) => {
  const locale = overrideLocale || window.navigator.language.slice(0, 2);
  const defaultLocale = overrideDefaultLocale || "en";

  const [messages, setMessages] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [usedLocale, setUsedLocale] = useState(locale);

  useEffect(() => {
    const loadLocale = async () => {
      try {
        setLoading(true);
        const { locale: usedLocale, messages } = await fetchLocale({
          locale,
          defaultLocale,
          directory,
        });
        setMessages(messages);
        setUsedLocale(usedLocale);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    loadLocale();
  }, [overrideLocale, overrideDefaultLocale, directory]);

  return { locale: usedLocale, messages, error, loading };
};

export default useLocale;

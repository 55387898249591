import React from "react";
import { PopUp } from "../PopUp/PopUp";
import { FormattedMessage } from "react-intl";

const PopUpError = ({ error, ...props }) => {
  const { message, response } = error;

  const fEMessage = response?.data?.message || message;
  const fEDescription = response?.data?.description;
  const feStatus = response?.data?.status;

  const title = <FormattedMessage id={`${fEMessage}_TITLE`} />;

  return (
    <PopUp title={title} {...props}>
      <p>
        <FormattedMessage id={`${fEMessage}_DESCRIPTION`} />
      </p>
    </PopUp>
  );
};

export { PopUpError };

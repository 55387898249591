import LOGIN_SCHEMA from "./src/LOGIN_SCHEMA.js";
import SIGNUP_SCHEMA from "./src/SIGNUP_SCHEMA.js";
import UPDATE_PASSWORD_SCHEMA from "./src/UPDATE_PASSWORD_SCHEMA.js";
import FORGOTTEN_PASSWORD_SCHEMA from "./src/FORGOTTEN_PASSWORD_SCHEMA.js";

export {
  LOGIN_SCHEMA,
  SIGNUP_SCHEMA,
  UPDATE_PASSWORD_SCHEMA,
  FORGOTTEN_PASSWORD_SCHEMA,
};

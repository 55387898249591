import React from "react";
import { Button } from "@ui/components";
import { FormattedMessage } from "react-intl";
import { PATHS } from "@paddle/const";
import urljoin from "url-join";

const CheckoutButton = ({ authUser, plan, callToAction = false }) => {
  const { priceId } = plan;
  const { email, _id } = authUser;

  const hanldeCheckout = () => {
    // eslint-disable-next-line no-undef
    Paddle.Checkout.open({
      settings: {
        displayMode: "overlay",
        successUrl: urljoin(window.location.origin, PATHS.CHECKOUT_SUCCESS),
        locale: window.navigator.language.slice(0, 2),
      },
      items: [
        {
          priceId,
          quantity: 1,
        },
      ],
      customer: {
        email,
      },
      customData: JSON.stringify({
        user: _id,
      }),
    });
  };

  return (
    <>
      <Button callToAction={callToAction} onClick={hanldeCheckout}>
        <FormattedMessage id="SUBSCRIBE" />
      </Button>
    </>
  );
};

export default CheckoutButton;

/**
 * @file UseList.js.
 * @author Jessy Leite
 */

/**
 * @module @frontxtemple/component-dashboard
 */

import { PAGINATION } from "@consts/common";
import { useState, useEffect } from "react";

const { OFFSET, LIMIT } = PAGINATION;

const useList = ({
  collection,
  offset = OFFSET,
  limit = LIMIT,
  short = false,
  filters,
  shouldExecute = true,
}) => {
  const { agent, name, possibleFilters } = collection;

  const customFilters = {};

  if (filters) {
    if (possibleFilters.length === 0) {
      console.warn(
        "If you want to use filter, you need to add the possible filters in the wanted collection"
      );
    }

    possibleFilters.forEach((filter) => {
      if (typeof filters[filter] === "string") {
        customFilters[filter] = filters[filter];
      }
    });
  }

  const [list, setList] = useState();
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (!shouldExecute) {
      return;
    }

    const getList = async () => {
      try {
        setError(null);
        setLoading(true);
        const req = await agent.list(offset, limit, short, customFilters);
        const { list, count } = req.data;
        setList(list);
        setCount(count);
      } catch (error) {
        console.error(error);
        console.error(new Error(`Error while loading ${name} list`));
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    getList();
  }, [agent, name, offset, limit, Object.values(customFilters).join("")]);

  return { loading, error, list, count };
};

export default useList;

import React, { lazy } from "react";
import { Route } from "react-router-dom";
import { PATHS } from "@paddle/const";

const CheckoutSuccess = lazy(() => import("./components/CheckoutSuccess"));

const Router = (
  <Route path={PATHS.CHECKOUT_SUCCESS} element={<CheckoutSuccess />} />
);

export default Router;

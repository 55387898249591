/**
 * @file Index.js.
 * @author Jessy Leite
 */

/**
 * @module @locale/client
 */

import useLocale from "./src/hooks/useLocale";

export { useLocale };

/**
 * @file Router.js.
 * @author Jessy Leite
 */

/**
 * @module @frontxtemple/dashboard
 */

import React, { lazy } from "react";
import { Route } from "react-router-dom";

const FrameGenerator = lazy(() => import("./FrameGenerator"));

const Router = (
  <Route path="/frame-generator/:id" element={<FrameGenerator />}></Route>
);

export default Router;

import React from "react";
import PropTypes from "prop-types";
import { InnerContent } from "../InnerContent/InnerContent";
import { FormattedMessage } from "react-intl";

export const ErrorContent = ({ error, short = false }) => {
  const { message, response } = error;

  const fEMessage = response?.data?.message || message;
  const fEDescription = response?.data?.description;
  const feStatus = response?.data?.status;

  return (
    <InnerContent>
      <div className="error">
        <p>
          ⚠️ <FormattedMessage id={`${fEMessage}_TITLE`} />
        </p>
        {fEMessage && !short && (
          <p>
            <FormattedMessage id={`${fEMessage}_DESCRIPTION`} />
          </p>
        )}
      </div>
    </InnerContent>
  );
};

ErrorContent.propTypes = {
  error: PropTypes.object.isRequired,
  short: PropTypes.bool,
};

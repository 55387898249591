/**
 * @file FILE.js.
 * @author Jessy Leite
 */

/**
 * @module @frontxtemple/consts
 */

/**.
 * File config
 *
 * @typedef {Object} FileConfig
 * @property {string} SUFFIX_ORIGINAL
 * @property {string} SVG
 * @property {string} TEXTURE
 */

/**
 * @constant
 * @type {FileConfig}
 * @default
 */
const FILE = {
  SUFFIX_ORIGINAL: "_original",
  SVG: "svg",
  TEXTURE: "texture",
};

Object.freeze(FILE);

export default FILE;

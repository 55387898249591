import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { FlexSpace } from "../FlexSpace/FlexSpace";

export const Nav = ({ links }) => {
  const linksLeft = [];
  const linksRight = [];
  links.forEach((link) => {
    const { title, to, right, end = false } = link;
    const linkEl = (
      <li key={to}>
        <NavLink
          to={to}
          className={({ isActive }) => (isActive ? "nav-link-active" : "")}
          end={end}
        >
          {title}
        </NavLink>
      </li>
    );
    if (right) {
      linksRight.push(linkEl);
    } else {
      linksLeft.push(linkEl);
    }
  });

  return (
    <nav className="menu menu-horizontal">
      <ul>
        {linksLeft}
        <FlexSpace />
        {linksRight}
      </ul>
    </nav>
  );
};

Nav.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

import _ from "lodash";

const findCollection = (collections, uniqName) => {
  const collection = _.find(collections, { uniqName });
  if (!collection) {
    throw new Error("Collection not found");
  }
  return collection;
};

export default findCollection;

import React from "react";
import { Aside } from "@ui/components";
import { Ref } from "@ui/ref";

const CollectionOneHeader = ({ one }) => {
  if (!one) {
    return null;
  }
  const { name, ref } = one;
  return (
    <Aside>
      <h1>{name}</h1>
      {ref && <Ref>{ref}</Ref>}
    </Aside>
  );
};

export default CollectionOneHeader;

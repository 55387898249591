import { instance } from "@global/axios";

/**
 * @constant
 * @type {{string: Function}}
 * @default
 */
const agent = {
  login: (values) => instance.post("login", values),
  signup: (values) => instance.post("signup", values),
  authUser: () => instance.get("auth-user"),
  updatePassword: (values) =>
    instance.post("/protected/update-password", values),
  forgottenPassword: (values) => instance.post("forgotten-password", values),
};

export default agent;

import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";

import useOne from "./hooks/useOne";
import useList from "./hooks/useList";
import {
  ErrorContent,
  LoadingContent,
  FieldLabelSelectSearch,
} from "@ui/components";
import { ReactListener } from "@utils/client";
import { mongoToOptions } from "@utils/client";
import { FormattedMessage } from "react-intl";

const CollectionOneCopy = ({ collection, setCopy }) => {
  return <></>;

  const [idToCopy, setIdToCopy] = useState();
  const handleValues = (values) => {
    setIdToCopy(values.idToCopy);
  };

  const {
    loading: loadingList,
    error: errorList,
    list,
  } = useList({
    collection,
    offset: 0,
    limit: 0,
    short: true,
  });

  const {
    loading: loadingCopy,
    error: errorCopy,
    one: copy,
  } = useOne({ collection, id: idToCopy });

  useEffect(() => {
    setCopy(copy);
  }, [copy]);

  if (loadingList) {
    return <LoadingContent />;
  }

  if (errorList) {
    return <ErrorContent error={errorList} />;
  }

  return (
    <Formik enableReinitialize initialValues={{ idToCopy: "" }}>
      {({ errors, touched, values }) => (
        <Form>
          <ReactListener values={values} callback={handleValues} />
          <FieldLabelSelectSearch
            name="idToCopy"
            options={mongoToOptions(list)}
            errors={errors}
            touched={touched}
            isSearchable={true}
            placeholder={<FormattedMessage id="IMPORT_FROM_ANOTHER" />}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CollectionOneCopy;

/**
 * @file GetQueryStrings.js.
 * @author Jessy Leite
 */

/**
 * @module @utils/common
 */

/**.
 * Send query strings
 * needs const search = useLocation().search;.
 *
 *
 * @param params
 * @param {string} params.search
 * @param {string[]} params.keys
 * @returns {{string: string}}
 * @example getQueryStrings({ search, keys: ['offset', 'limit'] })
 */
const getQueryStrings = ({ search, keys }) => {
  const queries = {};
  keys.forEach((key) => {
    queries[key] = new URLSearchParams(search).get(key);
  });
  return queries;
};

export default getQueryStrings;

import React from "react";
import { ScriptLoader } from "@utils/client";
import { PADDLE } from "@paddle/config";

const { SRC, SANDBOX, VENDOR_ID } = PADDLE;

const Provider = () => {
  return (
    <ScriptLoader
      src={SRC}
      onLoad={() => {
        if (SANDBOX) {
          // eslint-disable-next-line no-undef
          Paddle.Environment.set("sandbox");
        } else {
          // eslint-disable-next-line no-undef
          Paddle.Environment.set("production");
        }
        // eslint-disable-next-line no-undef
        Paddle.Setup({ seller: VENDOR_ID });
      }}
    />
  );
};

export default Provider;

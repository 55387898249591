import { useState, useEffect } from "react";
import agent from "../agent";

function useCustomer() {
  const [customer, setCustomer] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    const getCustomer = async () => {
      try {
        setError(null);
        setLoading(true);
        const { data } = await agent.customer();
        const { customer } = data;
        setCustomer(customer);
      } catch (error) {
        console.error(error);
        console.error(new Error(`Error while loading subscription user`));
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    getCustomer();
  }, []);

  return { loading, error, customer };
}

export default useCustomer;

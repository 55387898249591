const DIVISIONS = {
  LEFT_TOP: 0,
  RIGHT_TOP: 1,
  RIGHT_BOTTOM: 2,
  LEFT_BOTTOM: 3,
};

Object.freeze(DIVISIONS);

export default DIVISIONS;

import React from "react";
import { Outlet } from "react-router-dom";

import { Footer, Header } from "@ui/components";
import { useAuthUser } from "@auth/client";
import { FormattedMessage } from "react-intl";
import { WEBSITE_INFO } from "@frontxtemple/config";

const Template = ({ hasFooter = true }) => {
  const { authUser } = useAuthUser();

  const linksCommon = [
    {
      title: <FormattedMessage id="HOME" />,
      to: "/home",
      end: true,
    },
    {
      title: <FormattedMessage id="PRICING" />,
      to: "/pricing",
      right: true,
    },
  ];

  const linksHeader = [...linksCommon];

  let linksFooter = [];

  if (hasFooter) {
    linksFooter.push(...linksCommon);
  }

  const linksFooterInfo = [
    {
      title: <FormattedMessage id="LEGAL_NOTICE" />,
      to: "/legals/legal-notice",
    },
    {
      title: <FormattedMessage id="PRIVACY_POLICY" />,
      to: "/legals/privacy-policy",
    },
    {
      title: <FormattedMessage id="COOKIES_POLICY" />,
      to: "/legals/cookies-policy",
    },
    {
      title: <FormattedMessage id="TERMS_OF_USE" />,
      to: "/legals/terms-of-use",
    },
    {
      title: <FormattedMessage id="REFUND_POLICY" />,
      to: "/legals/refund-policy",
    },
  ];

  const linksMidFooter = [
    {
      title: <FormattedMessage id="ROADMAP" />,
      to: "/roadmap",
    },
    {
      title: <FormattedMessage id="COMMON_ISSUES" />,
      to: "/common-issues",
    },
  ];

  if (authUser) {
    linksHeader.push(
      ...[
        {
          title: <FormattedMessage id="DASHBOARD" />,
          to: "/dashboard",
          right: true,
        },
        {
          title: <FormattedMessage id="MY_ACCOUNT" />,
          to: "/my-account",
          right: true,
        },
        {
          title: <FormattedMessage id="LOGOUT" />,
          to: "/logout",
          right: true,
        },
      ]
    );
    if (hasFooter) {
      linksFooter.push(
        ...[
          {
            title: <FormattedMessage id="DASHBOARD" />,
            to: "/dashboard",
          },
          {
            title: <FormattedMessage id="MY_ACCOUNT" />,
            to: "/my-account",
          },
          ...linksMidFooter,
          {
            title: <FormattedMessage id="LOGOUT" />,
            to: "/logout",
          },
        ]
      );
    }
  } else {
    linksHeader.push(
      ...[
        {
          title: <FormattedMessage id="LOGIN" />,
          to: "/login",
          right: true,
        },
        {
          title: <FormattedMessage id="SIGNUP" />,
          to: "/signup",
          right: true,
        },
      ]
    );
    if (hasFooter) {
      linksFooter.push(
        ...[
          ...linksMidFooter,
          {
            title: <FormattedMessage id="LOGIN" />,
            to: "/login",
          },
          {
            title: <FormattedMessage id="SIGNUP" />,
            to: "/signup",
          },
        ]
      );
    }
  }

  return (
    <>
      <Header links={linksHeader} />
      <Outlet />
      {hasFooter && (
        <Footer
          links={linksFooter}
          websiteInfo={WEBSITE_INFO}
          linksInfo={linksFooterInfo}
        />
      )}
    </>
  );
};

export default Template;

import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function initialiseAnalytics(id) {
  if (!id) {
    throw new Error("Google Analytics ID is required");
  }

  ReactGA.initialize(id);
}

function useGA(id) {
  if (!id) {
    throw new Error("Google Analytics ID is required");
  }

  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    initialiseAnalytics(id);
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
    }
  }, [initialized, location]);
}

export default useGA;

import React from "react";
import PropTypes from "prop-types";
import { NavSide } from "../NavSide/NavSide";
import { Content } from "../Content/Content";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

export const Footer = ({ links = [], websiteInfo, linksInfo = [] }) => {
  const { CONTACT_EMAIL, START_DATE } = websiteInfo;

  const siteEndDate = new Date().getFullYear();

  const linksInfoEl = [];
  linksInfo.forEach((link) => {
    const { title, to } = link;
    linksInfoEl.push(
      <NavLink
        to={to}
        className={({ isActive }) => (isActive ? "nav-link-active" : "")}
        key={to}
      >
        {title}
      </NavLink>
    );
    linksInfoEl.push(" ");
  });

  return (
    <footer className="App-footer">
      <Content>
        <div className="reminder">
          <NavSide links={links} />
          <nav>
            <ul>
              <li>
                <a href={`mailto:${CONTACT_EMAIL}`}>
                  <FormattedMessage id="CONTACT_US" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="copyright">
          <FormattedMessage id="COPYRIGHT_TEXT" /> &copy;{" "}
          <FormattedMessage id="BRAND_NAME" /> {START_DATE}{" "}
          {START_DATE !== siteEndDate ? `- ${siteEndDate}` : ""} {linksInfoEl}
        </div>
      </Content>
    </footer>
  );
};

Footer.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

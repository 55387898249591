import React from "react";
import { FormattedMessage } from "react-intl";
import { Content, InnerContent, Article, Articles } from "@ui/components";

const Issue = ({ name, noImage }) => {
  return (
    <Article>
      <InnerContent>
        <h2>
          <FormattedMessage id={`ISSUE_${name}_TITLE`} />
        </h2>
      </InnerContent>
      {!noImage && (
        <InnerContent>
          <img src={`/images/issue/${name}.png`} style={{ width: "100%" }} />
        </InnerContent>
      )}
      <InnerContent>
        <p>
          <FormattedMessage id={`ISSUE_${name}_DESCRIPTION`} />
        </p>
      </InnerContent>
      <InnerContent>
        <p>
          <FormattedMessage id={`ISSUE_${name}_SOLUTION`} />
        </p>
      </InnerContent>
    </Article>
  );
};

const CommonIssues = () => (
  <Content limitedWidth>
    <InnerContent>
      <h1>
        <FormattedMessage id="COMMON_ISSUES" />
      </h1>
      <p className="big">
        <FormattedMessage id="HOW_TO_FIX" />
      </p>
    </InnerContent>
    <Articles>
      <Issue name="ERROR_CONSTRUCTION" />
      <Issue name="BRIDGE_WIDTH" />
      <Issue name="FILLETS" noImage />
    </Articles>
  </Content>
);

export default CommonIssues;

import React from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { toDataUrl } from "@utils/client";
import { Trash } from "grommet-icons";
import {
  IconButton,
  DivRelative,
  LoadingContent,
  ErrorContent,
} from "@ui/components";
import { useField, useFormikContext } from "formik";

const InputDragFile = ({
  name,
  text,
  previewEl,
  accept,
  loading,
  error,
  setWasDroped = () => {},
}) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (files) => {
      const file = files[0];
      const dataUrl = await toDataUrl(file);
      setFieldValue(name, dataUrl);
      setWasDroped(true);
    },
    accept,
  });

  const reset = () => {
    setFieldValue(name, null);
  };

  let content;

  if (!field.value || loading || error) {
    content = (
      <>
        {error && <ErrorContent error={error} />}
        <div {...getRootProps({ className: "dropzone" })}>
          {loading ? (
            <LoadingContent />
          ) : (
            <>
              <input {...getInputProps()} />
              <p>{text}</p>
            </>
          )}
        </div>
      </>
    );
  } else {
    content = (
      <DivRelative>
        <IconButton onClick={reset}>
          <Trash />
        </IconButton>
        {previewEl ? previewEl : <img src={field.value} alt="preview" />}
      </DivRelative>
    );
  }

  return <section className="container input-drag-file">{content}</section>;
};

InputDragFile.propTypes = {
  name: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  accept: PropTypes.object,
};

export { InputDragFile };

import React from "react";

export const DivRelative = ({ children }) => {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {children}
    </div>
  );
};

import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";

import useOne from "./hooks/useOne";
import useUpdateOne from "./hooks/useUpdateOne";
import { ErrorContent, LoadingContent, InnerContent } from "@ui/components";
import CollectionOneHeader from "./CollectionOneHeader";
import { ReactListener } from "@utils/client";
import { SubmitButton } from "@ui/submit-button";
import CollectionOneCopy from "./CollectionOneCopy";

const CollectionOne = ({ collection, withCopy = false }) => {
  const { id } = useParams();
  const { FormContent, validatorSchema } = collection;
  const [copy, setCopy] = useState();
  const [values, setValues] = useState();
  const [newInitialValues, setNewInitialValues] = useState();

  const { loading, error, one } = useOne({ collection, id });
  const {
    loading: updating,
    error: updateError,
    one: updated,
    updateOne,
  } = useUpdateOne({
    collection,
  });

  const navigate = useNavigate();
  useEffect(() => updated && navigate(-1), [updated]);

  useEffect(() => {
    if (!copy) {
      return;
    }
    const { _id } = one;
    setNewInitialValues({ ...copy, _id });
  }, [copy]);

  useEffect(() => {
    if (!one) {
      return;
    }
    setNewInitialValues(one);
  }, [one]);

  useEffect(() => {
    if (!updated) {
      return;
    }
    setNewInitialValues(updated);
  }, [updated]);

  const handleOnChange = (values) => {
    setValues(values);
  };

  const collectionOneCopy = useMemo(
    () =>
      withCopy && id === "new" ? (
        <InnerContent>
          <CollectionOneCopy collection={collection} setCopy={setCopy} />
        </InnerContent>
      ) : (
        <></>
      ),
    []
  );

  if (loading) {
    return <LoadingContent />;
  }

  if (error) {
    return <ErrorContent error={error} />;
  }

  return (
    <>
      <InnerContent>
        <CollectionOneHeader one={values} />
      </InnerContent>
      {collectionOneCopy}
      <InnerContent>
        <Formik
          enableReinitialize
          initialValues={newInitialValues}
          validationSchema={validatorSchema}
          onSubmit={updateOne}
        >
          {({ values, errors, touched }) => (
            <Form>
              {updateError && <ErrorContent error={updateError} short />}
              <ReactListener values={values} callback={handleOnChange} />
              <FormContent errors={errors} touched={touched} />
              <InnerContent>
                <SubmitButton loading={updating} />
              </InnerContent>
            </Form>
          )}
        </Formik>
      </InnerContent>
    </>
  );
};

CollectionOne.propTypes = {
  collection: PropTypes.object,
};

export default CollectionOne;

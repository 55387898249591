import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../IconButton/IconButton";
import { ReactComponent as Back } from "../../../../../assets/svg/back.svg";

export const IconButtonBack = ({ ...props }) => {
  const navigate = useNavigate();
  return (
    <IconButton onClick={() => navigate(-1)} {...props}>
      <Back />
    </IconButton>
  );
};

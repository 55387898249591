/**
 * @file Index.js.
 * @author Jessy Leite
 */

/**
 * @module @auth/client
 */

import Router from "./src/Router";
import Provider from "./src/Provider";
import CheckoutButton from "./src/components/CheckoutButton";
import ChangeButton from "./src/components/ChangeButton";
import Subscription from "./src/components/Subscription";
import usePlans from "./src/hooks/usePlans";

export {
  Router,
  Provider,
  CheckoutButton,
  Subscription,
  ChangeButton,
  usePlans,
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Loading, Prompt } from "../..";

export const IconButtonPrompt = ({
  type,
  loading,
  title,
  description,
  children,
  disabled,
  onClick = () => {},
}) => {
  const [onOpen, setOnOpen] = useState(() => () => {});
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {!isOpen && (
        <button
          type={type}
          disabled={disabled}
          className="icon-button"
          onClick={onOpen}
        >
          {loading ? <Loading /> : children}
        </button>
      )}
      <Prompt
        title={title}
        description={description}
        onClick={onClick}
        setOnOpen={setOnOpen}
        setIsOpen={setIsOpen}
        icon={children}
      />
    </>
  );
};

IconButtonPrompt.propTypes = {
  type: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
};

const GA_ACTIONS = {
  GENERATE: "generate",
  NEW: "new",
  UPDATE: "update",
  DELETE: "delete",
};

Object.freeze(GA_ACTIONS);

export default GA_ACTIONS;

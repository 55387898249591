import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import { NavHamburger } from "../NavHamburger/NavHamburger";
import { Content } from "../Content/Content";

export const Header = ({ links = [] }) => {
  const originUrl = window.location.origin;

  return (
    <header className="App-header">
      <FormattedMessage id="APP_TITLE">
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <FormattedMessage id="APP_DESCRIPTION">
        {(description) => (
          <Helmet>
            <meta name="description" content={description} />
          </Helmet>
        )}
      </FormattedMessage>
      <FormattedMessage id="APP_AUTHOR">
        {(author) => (
          <Helmet>
            <meta name="author" content={author} />
          </Helmet>
        )}
      </FormattedMessage>

      <meta name="twitter:card" content="summary_large_image" />
      <FormattedMessage id="APP_TWITTER_TITLE">
        {(twitterTitle) => (
          <Helmet>
            <meta name="twitter:title" content={twitterTitle} />
          </Helmet>
        )}
      </FormattedMessage>
      <FormattedMessage id="APP_TWITTER_DESCRIPTION">
        {(twitterDescription) => (
          <Helmet>
            <meta name="twitter:description" content={twitterDescription} />
          </Helmet>
        )}
      </FormattedMessage>
      <Helmet>
        <meta
          name="twitter:image"
          content={`${originUrl}/images/meta/twitter.png`}
        />
      </Helmet>

      <FormattedMessage id="APP_OG_TITLE">
        {(ogTitle) => (
          <Helmet>
            <meta property="og:title" content={ogTitle} />
          </Helmet>
        )}
      </FormattedMessage>
      <FormattedMessage id="APP_OG_DESCRIPTION">
        {(ogDescription) => (
          <Helmet>
            <meta property="og:description" content={ogDescription} />
          </Helmet>
        )}
      </FormattedMessage>
      <Helmet>
        <meta property="og:image" content={`${originUrl}/images/meta/og.png`} />
        <meta property="og:url" content={originUrl} />
      </Helmet>

      <Content>
        <NavHamburger links={links} />
      </Content>
    </header>
  );
};

Header.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

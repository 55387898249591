import React from "react";
import { Loading, Content } from "../..";

import "./loadingContent.css";

export const LoadingContent = () => {
  return (
    <Content verticalCenter>
      <div className="loading-content">
        <Loading />
      </div>
    </Content>
  );
};

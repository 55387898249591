import React, { lazy } from "react";
import { Route } from "react-router-dom";

const Legals = lazy(() => import("./Legals"));

const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./TermsOfUse"));
const CookiesPolicy = lazy(() => import("./CookiesPolicy"));
const LegalNotice = lazy(() => import("./LegalNotice"));
const RefundPolicy = lazy(() => import("./RefundPolicy"));

const Router = (
  <>
    <Route path="/legals" element={<Legals />}>
      <Route path="legal-notice" element={<LegalNotice />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="terms-of-use" element={<TermsOfUse />} />
      <Route path="cookies-policy" element={<CookiesPolicy />} />
      <Route path="refund-policy" element={<RefundPolicy />} />
    </Route>
  </>
);

export default Router;

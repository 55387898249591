/**
 * @file PLATFORM_NAMES.js.
 * @author Jessy Leite
 */

/**
 * @module @frontxtemple/consts
 */

/**.
 * Platform Names
 *
 * @typedef {Object} PlatformNames
 * @property {string} FRONT
 * @property {string} TEMPLE
 * @property {string} RIVET
 */

/**
 * @constant
 * @type {PlatformNames}
 * @default
 */
const PLATFORM_NAMES = {
  PRESTASHOP: "PRESTASHOP",
};

Object.freeze(PLATFORM_NAMES);

export default PLATFORM_NAMES;

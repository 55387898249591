/**
 * @file Index.js.
 * @author Jessy Leite
 */

/**
 * @module @utils/common
 */

import getQueryStrings from "./src/getQueryStrings.js";
import isDataUrlBase64 from "./src/isDataUrlBase64.js";
import getDotString from "./src/getDotString.js";
import getNameOf from "./src/getNameOf.js";
import checkDictHas from "./src/checkDictHas.js";
import deepFreeze from "./src/deepFreeze.js";
import dictToOptions from "./src/dictToOptions.js";
import removeQueryParams from "./src/removeQueryParams.js";
import createPagination from "./src/createPagination.js";
import getDataURLType from "./src/getDataURLType.js";
import findConsecutiveIndexes from "./src/findConsecutiveIndexes.js";

export {
  getQueryStrings,
  isDataUrlBase64,
  getDotString,
  getNameOf,
  checkDictHas,
  deepFreeze,
  dictToOptions,
  removeQueryParams,
  createPagination,
  getDataURLType,
  findConsecutiveIndexes,
};

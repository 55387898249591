import { useState } from "react";
import agent from "../agent";

const useCancel = () => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const cancel = async () => {
    try {
      setLoading(true);
      const { data } = await agent.cancel();
      const { success } = data;
      setSuccess(success);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { cancel, success, loading, error };
};

export default useCancel;

const USE_LOCAL_BASE =
  process.env.USE_LOCAL_BASE == 1 || process.env.REACT_APP_USE_LOCAL_BASE == 1
    ? true
    : false;
const USE_LOCAL_TOOLS = process.env.USE_LOCAL_TOOLS == 1 ? true : false;
const USE_LOCAL_CLIENT = process.env.USE_LOCAL_CLIENT == 1 ? true : false;
const IS_DOCKER = process.env.IS_DOCKER == 1 ? true : false;

const ENV = {
  USE_LOCAL_BASE,
  USE_LOCAL_TOOLS,
  USE_LOCAL_CLIENT,
  IS_DOCKER,
};

Object.freeze(ENV);

export default ENV;

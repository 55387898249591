/**
 * @file SIGNUP_SCHEMA.js.
 * @author Jessy Leite
 */

/**
 * @module @auth/validators
 */

import LOGIN_SCHEMA from "./LOGIN_SCHEMA.js";

/**
 * @constant
 * @type {object}
 * @default
 */
const SIGNUP_SCHEMA = LOGIN_SCHEMA.shape({
  // add signup specific things as name, firstname, etc
});

export default SIGNUP_SCHEMA;

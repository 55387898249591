const FILLETS = {
  NO: "NO",
  QUARTER: "QUARTER",
  HALF: "HALF",
  THREE_QUARTERS: "THREE_QUARTERS",
  FULL: "FULL",
};

Object.freeze(FILLETS);

export default FILLETS;

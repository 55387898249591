import React from "react";
import styled from "styled-components";
import { InnerContent } from "../..";
import { getHEl } from "@utils/client";

const Article = ({
  title,
  description,
  centeredHeader = true,
  centeredContent = false,
  children,
  h = 1,
}) => {
  const hEl = getHEl(h, title);

  return (
    <article>
      <InnerContent>
        <ArticleHeader centered={centeredHeader}>
          {hEl}
          {description && <p>{description}</p>}
        </ArticleHeader>
      </InnerContent>
      <InnerContent>
        <ArticleContent centered={centeredContent}>{children}</ArticleContent>
      </InnerContent>
    </article>
  );
};

const ArticleHeader = styled.div`
  ${(props) => props.centered && "text-align: center;"}
`;

const ArticleContent = styled.div`
  ${(props) => props.centered && "text-align: center;"}
  margin-top: var(--padding-y);
`;

export { Article };

import React, { useState } from "react";
import styled from "styled-components";

const Group = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--padding-x);
`;

const Item = styled.div`
  width: 100%;
  height: 70vh;
  @media (orientation: portrait) {
    height: 50vh;
  }
  background-position: center;
  background-size: cover;
  border-radius: 3vw;
  cursor: pointer;
  transition-property: width;
  background-image: url(${({ imageUrl }) => imageUrl});
  transition: 0.4s cubic-bezier(0.47, 0.8, 0.2, 1);
  ${({ selected }) =>
    selected &&
    "width: 100%; transition: 1s cubic-bezier(0.64, 0.57, 0.67, 1.53);"};
  ${({ notSelected }) => notSelected && "width: 8vw;"};
`;

export const ElasticAccordion = ({ images }) => {
  const [selected, setSelected] = useState(-1);
  return (
    <Group>
      {images.map((image, i) => (
        <Item
          onClick={() => setSelected(selected !== i ? i : -1)}
          key={i}
          imageUrl={image}
          selected={selected === i}
          notSelected={selected !== i && selected !== -1}
        />
      ))}
    </Group>
  );
};

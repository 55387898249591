import React, { useState, useRef, useEffect, useMemo } from "react";
import { Field, useFormikContext, useField } from "formik";
import styled from "styled-components";
import { LabelTitle } from "../LabelTitle/LabelTitle";
import { FieldError } from "../FieldError/FieldError";
import { degToRad, digits, radToDeg } from "@math/number";
import { round } from "lodash";
import { FormattedMessage } from "react-intl";
import { UNITS } from "@consts/common";
import { ReactComponent as Plus } from "../../../../../assets/svg/plus.svg";
import { ReactComponent as Minus } from "../../../../../assets/svg/minus.svg";
import { Aside } from "../Aside/Aside";

const { DEGREES } = UNITS;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-input);
  position: relative;

  input {
    border: none;
    border-shadow: none;
    box-shadow: none;
    padding: var(--padding-input);
    padding-right: 0;
    text-align: right;
    position: absolute;
    right: 50%;
    bottom: calc(var(--padding-input) / 2);

    &:focus {
      outline: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const FieldHover = styled.div`
  position: absolute;
  left: 25%;
  top: var(--padding-input);
  width: 50%;
  height: calc(100% - var(--padding-input) * 2);
  cursor: pointer;
  border-radius: var(--border-radius);
  border-color: transparent;
  border-width: medium;
  transition: border-color var(--medium-transition-time) ease-in-out;
  &:hover {
    border-style: solid;
    border-color: var(--purple);
  }
  ${(props) =>
    props.focused && "border-style: solid; border-color: var(--purple);"}
`;

const FieldFocuser = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  &:hover {
    cursor: text;
  }
`;

const Unit = styled.div`
  position: absolute;
  left: 50%;
  bottom: calc(var(--padding-input) * 1.5);
`;

const IncrementButton = styled.button.attrs((props) => ({
  className: "icon-button",
}))`
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  border-radius: 50%;
  border none;
  cursor: pointer;
  transition: all var(--medium-transition-time) ease-in-out;
  width: 2rem;
  height: 2rem;
  color: var(--primary-color);

  &:hover {
    background-color: var(--purple);
    color: var(--background-color);
    transform: unset;
    & svg * {
      fill: var(--background-color) !important;
      stroke: var(--background-color) !important;
    }
  }
  &:focus {
    outline: none;
  }

  ${(props) => !props.enabled && "opacity: 0;"}

`;

export const FieldLabelNumberStepper = ({
  name,
  step = 0.1,
  inline = false,
  unit = null,
  nextToLabel = null,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const [focused, setFocused] = useState(false);
  const toggleFocus = () => setFocused(!focused);

  let formikRef = useRef(null);

  useMemo(() => {
    if (formikRef.current) {
      formikRef.current.addEventListener("focus", () => setFocused(true));
      formikRef.current.addEventListener("blur", () => setFocused(false));
    }
  }, [formikRef.current]);

  useEffect(() => {
    if (focused) {
      formikRef.current.focus();
    }
  }, [focused]);

  let onChange = (e) => {
    setFieldValue(name, Number(e.target.value));
  };

  const isAngle = unit === DEGREES;

  const roundValue = isAngle ? 3 : digits(step);

  const min = isAngle ? degToRad(props.min) : props.min;
  const max = isAngle ? degToRad(props.max) : props.max;

  if (isAngle) {
    onChange = (e) => {
      setFieldValue(name, degToRad(e.target.value));
    };
  }

  const valueToIncrement = isAngle ? degToRad(step) : step;

  const incrementValue = (e) => {
    e.preventDefault();
    const newValue = round(field.value, roundValue) + valueToIncrement;
    if (newValue <= max) {
      setFieldValue(name, newValue);
    }
  };

  const decrementValue = (e) => {
    e.preventDefault();
    const newValue = round(field.value, roundValue) - valueToIncrement;
    if (newValue >= min) {
      setFieldValue(name, newValue);
    }
  };

  const labelEl = <LabelTitle name={name} {...props} />;
  const fieldEl = (
    <InputWrapper>
      <FieldFocuser onClick={toggleFocus} />
      <IncrementButton
        type="button"
        onClick={decrementValue}
        enabled={field.value > min}
      >
        <Minus />
      </IncrementButton>
      <FieldHover {...{ focused }} />
      <Field
        type="number"
        name={name}
        min={min}
        max={max}
        step={step}
        value={
          isAngle
            ? round(radToDeg(field.value, roundValue))
            : round(field.value, roundValue)
        }
        innerRef={formikRef}
        onChange={onChange}
      />
      {unit && (
        <Unit>
          &nbsp;
          <FormattedMessage id={unit} />
        </Unit>
      )}
      <IncrementButton
        type="button"
        onClick={incrementValue}
        enabled={field.value < max}
      >
        <Plus />
      </IncrementButton>
    </InputWrapper>
  );

  return (
    <div className={inline ? "inline" : ""}>
      <Aside verticalAlign>
        {labelEl} {nextToLabel}
      </Aside>
      {fieldEl}
      <FieldError name={name} {...props} />
    </div>
  );
};

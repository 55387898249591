import React from "react";

export const Content = ({
  limitedWidth = false,
  verticalCenter = false,
  children,
  ...props
}) => {
  return (
    <div
      className={[
        "content",
        limitedWidth ? "limited-width" : "",
        verticalCenter ? "vertical-center" : "",
      ].join(" ")}
      {...props}
    >
      {children}
    </div>
  );
};

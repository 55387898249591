import React from "react";
import styled from "styled-components";

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
`;

const VideoIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const VimeoVideo = ({
  videoId,
  title = false,
  byline = false,
  portrait = false,
}) => {
  const embedUrl = `https://player.vimeo.com/video/${videoId}?title=${title}&byline=${byline}&portrait=${portrait}`;

  return (
    <VideoContainer>
      <VideoIframe
        src={embedUrl}
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </VideoContainer>
  );
};

export { VimeoVideo };

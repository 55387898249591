const STATUS = {
  ACTIVE: "active",
  CANCELED: "canceled",
  PAST_DUE: "past_due",
  PAUSED: "paused",
  TRIALING: "trialing",
};

Object.freeze(STATUS);

export default STATUS;

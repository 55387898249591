import React from "react";
import useChange from "../hooks/useChange";
import { ErrorContent } from "@ui/error-content";
import { Button } from "@ui/components";
import { FormattedMessage } from "react-intl";

const ChangeButton = ({ productId, priceId }) => {
  const { change, success, loading, error } = useChange();

  if (error) {
    return <ErrorContent error={error} />;
  }

  if (success) {
    return <FormattedMessage id="PLAN_CHANGED" />;
  }

  return (
    <Button loading={loading} onClick={() => change({ productId, priceId })}>
      <FormattedMessage id="CHANGE_PLAN" />
    </Button>
  );
};

export default ChangeButton;

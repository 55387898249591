import React from "react";
import { Button } from "@ui/components";
import { FormattedMessage } from "react-intl";

export const SubmitButton = ({ loading }) => {
  return (
    <Button type="submit" title="Submit" loading={loading} callToAction={true}>
      <FormattedMessage id="SAVE" />
    </Button>
  );
};

import React from "react";
import { LabelTitle } from "../LabelTitle/LabelTitle";
import { FieldError } from "../FieldError/FieldError";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";
import Select from "react-select";

export const FieldLabelSelectSearch = ({
  name,
  options,
  isSearchable = false,
  placeholder,
  ...props
}) => {
  let optionsWithNone = [...options];

  return (
    <label>
      {/*<LabelTitle name={name} {...props} />
      <FieldError name={name} {...props} />*/}
      <Field
        name={name}
        id={name}
        placeholder={placeholder}
        isMulti={false}
        component={SelectOne}
        options={optionsWithNone}
        isSearchable={isSearchable}
      />
    </label>
  );
};

const SelectOne = ({
  field,
  form,
  options,
  placeholder = "Select",
  isSearchable = { isSearchable },
}) => {
  return (
    <div className="select">
      <Select
        options={options}
        name={field.name}
        value={
          options
            ? options.find((option) => option.value === field.value)
            : null
        }
        onChange={(option) => form.setFieldValue(field.name, option.value)}
        onBlur={field.onBlur}
        placeholder={placeholder}
        isSearchable={isSearchable}
      />
    </div>
  );
};

import { instance } from "@global/axios";
import { useState, useEffect } from "react";

const agent = {
  assets: () => instance.get("/protected/assets/all-materials"),
};

function useAssetsAllMaterials() {
  const [assets, setAssets] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    const getAssets = async () => {
      try {
        setLoading(true);
        const { data } = await agent.assets();
        const { assets } = data;
        setAssets(assets);
      } catch (error) {
        console.error(error);
        console.error(new Error(`Error while loading assets`));
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    getAssets();
  }, []);

  return { loading, error, assets };
}

export default useAssetsAllMaterials;

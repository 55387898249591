import { useState } from "react";
import agent from "../agent";

const useUpdatePassword = () => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const updatePassword = async (values) => {
    try {
      setLoading(true);
      const { data } = await agent.updatePassword(values);
      const { success } = data;
      setSuccess(success);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { updatePassword, success, loading, error };
};

export default useUpdatePassword;

import { init } from "@global/axios";
import { REMOTE_URL } from "@frontxtemple/config";
import React from "react";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import { Notificator } from "@ui/notificator";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider as ProviderAuth } from "@auth/client";
import { Provider as ProviderPaddle } from "@paddle/client";
import { initSentry } from "@sentry/client";
import { SENTRY } from "@frontxtemple/config";
import { Leva } from "leva";

const { BASE_URL } = REMOTE_URL;
const { FXT_CLIENT_DSN, FXT_CLIENT_TRACING_SAMPLE_RATE } = SENTRY;

initSentry({
  dsn: FXT_CLIENT_DSN,
  tracesSampleRate: FXT_CLIENT_TRACING_SAMPLE_RATE,
});

init({
  baseURL: BASE_URL,
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ProviderAuth />
      <ProviderPaddle />
      <Leva hidden={process.env.REACT_APP_LEVA !== "true"} />
      <App />
    </RecoilRoot>
    <Notificator />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

import DRAWING_TO_3D from "./src/DRAWING_TO_3D.js";
import IMAGE_TO_SVG from "./src/IMAGE_TO_SVG.js";
import PYTHON from "./src/PYTHON.js";
import ENV from "./src/ENV.js";
import REMOTE_URL from "./src/REMOTE_URL.js";
import TOOLS_API from "./src/TOOLS_API.js";
import GOOGLE_ANALYTICS from "./src/GOOGLE_ANALYTICS.js";
import SENTRY from "./src/SENTRY.js";
import GLOBAL_MATERIAL_TAGS from "./src/GLOBAL_MATERIAL_TAGS.js";
import TIMEOUT from "./src/TIMEOUT.js";
import WEBSITE_INFO from "./src/WEBSITE_INFO.js";
import CREDITS_USING from "./src/CREDITS_USING.js";
import GIVEAWAY from "./src/GIVEAWAY.js";

const DEFAULT = {
  DRAWING_TO_3D,
  IMAGE_TO_SVG,
  PYTHON,
  ENV,
  REMOTE_URL,
  TOOLS_API,
  GOOGLE_ANALYTICS,
  SENTRY,
  GLOBAL_MATERIAL_TAGS,
  TIMEOUT,
  WEBSITE_INFO,
  CREDITS_USING,
  GIVEAWAY,
};

export {
  DEFAULT as default,
  DRAWING_TO_3D,
  IMAGE_TO_SVG,
  PYTHON,
  ENV,
  REMOTE_URL,
  TOOLS_API,
  GOOGLE_ANALYTICS,
  SENTRY,
  GLOBAL_MATERIAL_TAGS,
  TIMEOUT,
  WEBSITE_INFO,
  CREDITS_USING,
  GIVEAWAY,
};

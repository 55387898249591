import React from "react";
import PropTypes from "prop-types";

export const Options = ({ options }) => {
  const elOptions = options.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  return elOptions;
};

const PropTypesOptions = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

Options.propTypes = {
  options: PropTypes.arrayOf(PropTypesOptions).isRequired,
};

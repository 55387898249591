import React, { useState } from "react";
import styled from "styled-components";
import { PopUp } from "../..";
import { FormattedMessage } from "react-intl";

// Define styled components
const HelpButtonContainer = styled.div`
  position: relative;
`;

const Button = styled.button`
  font-size: var(--font-size);
  color: var(--purple);
  cursor: pointer;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  min-width: 0 !important;
  background-color: transparent !important;
`;

const HelpButton = ({ imageSrc }) => {
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const toggleHelp = () => {
    setIsHelpOpen(!isHelpOpen);
  };

  return (
    <HelpButtonContainer>
      <Button onClick={toggleHelp}>?</Button>
      {isHelpOpen && (
        <PopUp title={<FormattedMessage id="HELP" />} closePopUp={toggleHelp}>
          <img src={imageSrc} />
        </PopUp>
      )}
    </HelpButtonContainer>
  );
};

export { HelpButton };

import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { instance } from "@global/axios";
import { tokenState } from "./states";
import { JWT } from "@auth/config";

const { TOKEN } = JWT;

const Provider = () => {
  const [token, setToken] = useRecoilState(tokenState);

  useEffect(() => {
    instance.defaults.headers.common = { Authorization: `bearer ${token}` };
    window.localStorage.setItem(TOKEN, token);
  }, [token]);

  useEffect(() => {
    const token = window.localStorage.getItem(TOKEN);
    if (!token) {
      return;
    }
    setToken(token);
  }, []);

  return <></>;
};

export default Provider;

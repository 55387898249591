import React from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Pagination } from "@ui/components";
import { getQueryStrings } from "@utils/common";
import useList from "./hooks/useList";

const CollectionList = ({ collection }) => {
  const { ListContent, config } = collection;
  const { pagination } = config;
  const search = useLocation().search;
  let { offset, limit } = getQueryStrings({
    search,
    keys: ["offset", "limit"],
  });
  offset = parseInt(offset) || pagination.offset;
  limit = parseInt(limit) || pagination.limit;

  const { loading, error, list, count } = useList({
    collection,
    offset,
    limit,
  });
  return (
    <div className="assets">
      {pagination.enabled && (
        <Pagination offset={offset} limit={limit} count={count} />
      )}
      <ListContent
        collection={collection}
        list={list}
        error={error}
        loading={loading}
      />
    </div>
  );
};

CollectionList.propTypes = {
  collection: PropTypes.object,
};

export default CollectionList;

import { useState } from "react";

function useRemoveOne({ collection }) {
  const { agent, name } = collection;
  const [removed, setRemoved] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const removeOne = async (id) => {
    try {
      setError(null);
      setLoading(true);
      const { data } = await agent.removeOne(id);
      const { removed } = data;
      setRemoved(removed);
    } catch (error) {
      console.error(error);
      console.error(
        new Error(`Error while removing ${name}, see details above`)
      );
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { removeOne, loading, error, removed };
}

export default useRemoveOne;

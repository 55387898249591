import React from "react";
import "./loading.css";

export const Loading = () => {
  return (
    <div className="loading">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

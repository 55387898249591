import { Field } from "formik";
import React from "react";
import { LabelTitle } from "../LabelTitle/LabelTitle";
import { FieldError } from "../FieldError/FieldError";
import { useFormikContext } from "formik";

export const FieldLabel = ({
  name,
  type,
  placeholder,
  inline = true,
  ...props
}) => {
  const labelEl = <LabelTitle name={name} {...props} />;
  const fieldEl = <Field name={name} type={type} placeholder={placeholder} />;
  const context = useFormikContext();

  return (
    <label className={inline ? "inline" : ""}>
      {labelEl}
      {fieldEl}
      <FieldError name={name} {...context} />
    </label>
  );
};

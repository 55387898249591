import { instance } from "@global/axios";

const agent = {
  change: ({ priceId, productId }) =>
    instance.post(`/protected/is-customer/change`, {
      priceId,
      productId,
    }),
  customer: () => instance.get("/protected/is-customer/customer"),
  plans: () => instance.get("/plans"),
  cancel: () => instance.get(`/protected/is-customer/cancel`),
};

export default agent;

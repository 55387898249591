import areNumbersClose from "./src/areNumbersClose.js";
import degToRad from "./src/degToRad.js";
import radToDeg from "./src/radToDeg.js";
import isDivisibleBy from "./src/isDivisibleBy.js";
import mid from "./src/mid.js";
import arrayAverage from "./src/arrayAverage.js";
import numberOfZeros from "./src/numberOfZeros.js";
import isOdd from "./src/isOdd.js";
import fromSeed from "./src/fromSeed.js";
import digits from "./src/digits.js";

export {
  areNumbersClose,
  degToRad,
  radToDeg,
  isDivisibleBy,
  mid,
  arrayAverage,
  numberOfZeros,
  isOdd,
  fromSeed,
  digits,
};

const EVENT_TYPES = {
  SUBSCRIPTION_ACTIVATED: "subscription.activated",
  SUBSCRIPTION_CANCELED: "subscription.canceled",
  SUBSCRIPTION_CREATED: "subscription.created",
  SUBSCRIPTION_PAST_DUE: "subscription.past_due",
  SUBSCRIPTION_PAUSED: "subscription.paused",
  SUBSCRIPTION_RESUMED: "subscription.resumed",
  SUBSCRIPTION_TRIALING: "subscription.trialing",
  SUBSCRIPTION_UPDATED: "subscription.updated",
  TRANSACTION_BILLED: "transaction.billed",
  TRANSACTION_CANCELED: "transaction.canceled",
  TRANSACTION_COMPLETED: "transaction.completed",
  TRANSACTION_CREATED: "transaction.created",
  TRANSACTION_PAST_DUE: "transaction.past_due",
  TRANSACTION_PAYMENT_FAILED: "transaction.payment_failed",
  TRANSACTION_READY: "transaction.ready",
  TRANSACTION_UPDATED: "transaction.updated",
};

Object.freeze(EVENT_TYPES);

export default EVENT_TYPES;

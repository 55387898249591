import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { LoadingContent } from "@ui/components";
import { notify } from "@ui/notificator";
import { useAuthUser, Router as RouterAuth } from "@auth/client";
import { Router as RouterDashboard } from "@frontxtemple/dashboard";
import { Router as RouterFrameGenerator } from "@frontxtemple/frame-generator";
import { Router as RouterLegals } from "@frontxtemple/legals";
import { useLocale } from "@locale/client";
import Error404 from "./errors/Error404";
import "./App.css";
import Template from "./Template";
import { GOOGLE_ANALYTICS } from "@frontxtemple/config";
import { PopUpProvider } from "@ui/pop-upper";
import { Helmet } from "react-helmet";
import CommonIssues from "./pages/CommonIssues";

import { Provider as ProviderGA } from "@google-analytics/client";
const { ID: GA_ID } = GOOGLE_ANALYTICS;

const Home = React.lazy(() => import("./pages/Home"));
const Pricing = React.lazy(() => import("./pages/Pricing"));
const Roadmap = React.lazy(() => import("./pages/Roadmap"));

function App() {
  const { locale, messages, errorLocale, loading } = useLocale({
    directory: "/langs/frontxtemple",
  });

  if (errorLocale) {
    notify(`Error while loading lang ${locale}`);
  }

  useAuthUser();

  if (loading) {
    return <LoadingContent />;
  }

  return (
    <IntlProvider locale={locale} messages={messages}>
      <Helmet>
        <html lang={locale} />
      </Helmet>
      <Suspense fallback={<LoadingContent />}>
        <PopUpProvider>
          <BrowserRouter>
            <ProviderGA id={GA_ID} />
            <Routes>
              {RouterFrameGenerator}
              <Route path="/" element={<Template />}>
                <Route exact path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={<Home />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/roadmap" element={<Roadmap />} />
                <Route path="/common-issues" element={<CommonIssues />} />
                <Route path="*" element={<Error404 />} />
              </Route>
              <Route path="/" element={<Template hasFooter={true} />}>
                {RouterDashboard}
                {RouterAuth}
                {RouterLegals}
              </Route>
            </Routes>
          </BrowserRouter>
        </PopUpProvider>
      </Suspense>
    </IntlProvider>
  );
}

export default App;

const WORKING_DIR = "/tmp";

const IMAGE_TO_SVG = {
  WORKING_DIR,
  TARGETED_WIDTH: 140,
  POTRACE_CMD: "potrace",
  MIN_BLACK_PIXELS_PERCENT_TO_BE_VALID: 5,
  MIN_SHAPE_WIDTH_TO_BE_VALID: 50,
  MIN_ASPECT_RATIO_TO_BE_TEMPLE: 5,
  WHITE_BORDER_WIDTH: 10,
  MAX_WIDTH: 1500,
};

Object.freeze(IMAGE_TO_SVG);

export default IMAGE_TO_SVG;

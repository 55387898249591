import React from "react";

export const FormContent = ({ children, inline = false, ...props }) => {
  return (
    <div
      className={["form-content", inline ? "inline" : ""].join(" ")}
      {...props}
    >
      {children}
    </div>
  );
};

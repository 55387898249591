import EVENT_TYPES from "./src/EVENT_TYPES.js";
import STATUS from "./src/STATUS.js";
import PATHS from "./src/PATHS.js";

const DEFAULT = {
  EVENT_TYPES,
  STATUS,
  PATHS,
};

export { DEFAULT as default, STATUS, EVENT_TYPES, PATHS };

const DRAWING_TO_3D_ACTIONS = {
  FREECAD_GENERATE_STEP: "freecad generate step",
  FREECAD_GENERATE_STL: "freecad generate stl",
  FREECAD_WELDING: "freecad welding",
  BLENDER_UV: "blender uv",
  BLENDER_BEND: "blender bend",
  BLENDER_DISPLACEMENT: "blender displacement",
  BLENDER_GENERATE_STL: "blender generate stl",
  BLENDER_GENERATE_GLB: "blender generate glb",
  BLENDER_GENERATE_GLB_DRACO: "blender generate glb draco",
  BLENDER_BEND_TEMPLE: "blender bend temple",
  BLENDER_WELDING: "blender welding",
};

export default DRAWING_TO_3D_ACTIONS;

import React from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export const LinkBack = ({ ...props }) => {
  const navigate = useNavigate();
  return (
    <a onClick={() => navigate(-1)} {...props}>
      <FormattedMessage id="BACK" />
    </a>
  );
};

import React, { useState } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const Label = styled.label`
  font-size: 1.2rem;
  font-weight: bold;
`;

const Select = styled.select`
  color: var(--primary-color);
  font-size: 1rem;
`;

const Option = styled.option`
  font-size: 1rem;
  background-color: var(--background-color);
  color: var(--primary-color);
`;

export const InputSelect = ({ label, options, value, onChange, ...rest }) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Select value={selectedValue} onChange={handleChange} {...rest}>
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          <FormattedMessage id={option.label} />
        </Option>
      ))}
    </Select>
  );
};

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link } from "react-router-dom";

const TableCell = ({ doc, docKey, head }) => {
  const header = _.find(head, { key: docKey });
  const datum = doc[docKey];
  return (
    <td>
      {header.link ? <Link to={datum}>{header.customContent}</Link> : datum}
    </td>
  );
};

TableCell.propTypes = {
  doc: PropTypes.object,
  docKey: PropTypes.string,
  head: PropTypes.arrayOf(PropTypes.object),
};

export default TableCell;

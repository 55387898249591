const S3 = "S3";
const DB = "DB";
const UNKNOWN = "UNKNOWN";
const AUTH = "AUTH";
const MAILCHIMP = "MAILCHIMP";
const MAILER = "MAILER";
const BODY_PARSER = "BODY_PARSER";
const PADDLE = "PADDLE";

export { S3, DB, UNKNOWN, AUTH, MAILCHIMP, MAILER, BODY_PARSER, PADDLE };

import React, { useEffect } from "react";

const ScriptLoader = ({ src, onLoad }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = src;

    script.onload = () => {
      if (onLoad && typeof onLoad === "function") {
        onLoad();
      }
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [src, onLoad]);

  return null;
};

export default ScriptLoader;

import { Field, useFormikContext, useField } from "formik";
import React from "react";
import { LabelTitle } from "../LabelTitle/LabelTitle";
import { FieldError } from "../FieldError/FieldError";
import { degToRad, radToDeg } from "@math/number";
import { round } from "lodash";

export const FieldLabelNumber = ({
  name,
  min = 0,
  max = 1,
  step = 0.1,
  isAngle = false,
  inline = true,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  let onChange = (e) => setFieldValue(name, Number(e.target.value));

  if (isAngle) {
    onChange = (e) => {
      setFieldValue(name, degToRad(e.target.value));
    };
  }

  const labelEl = <LabelTitle name={name} {...props} />;
  const fieldEl = (
    <Field
      type="number"
      name={name}
      min={min}
      max={max}
      step={step}
      value={isAngle ? round(radToDeg(field.value), 2) : field.value}
      onChange={onChange}
    />
  );

  return (
    <label className={inline ? "inline" : ""}>
      {labelEl}
      {fieldEl}
      <FieldError name={name} {...props} />
    </label>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { toDataUrl } from "@utils/client";

export const InputFile = ({ name, displayName, accept, action }) => {
  return (
    <label>
      <input
        type="file"
        id={name}
        name={name}
        accept={accept}
        onChange={async (event) => {
          const file = event.target.files[0];
          const dataUrl = await toDataUrl(file);
          action(name, dataUrl);
        }}
      />
    </label>
  );
};

InputFile.propTypes = {
  name: PropTypes.string,
  displayName: PropTypes.string,
  accept: PropTypes.string,
  action: PropTypes.func,
};

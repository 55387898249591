const EXPORT_TYPES = {
  WEB: "WEB",
  PRINTING_3D: "PRINTING_3D",
  PROTO_3D: "PROTO_3D",
  STEP: "STEP",
};

Object.freeze(EXPORT_TYPES);

export default EXPORT_TYPES;

import urlJoin from "url-join";

const getFullPath = (collection, options) => {
  const { path } = collection;
  if (options?.isProtected) {
    return urlJoin("/protected", path);
  } else {
    return urlJoin(path);
  }
};

export default getFullPath;

import axios from "axios";
import { useState, useEffect } from "react";

function useFile({ url }) {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (!url) {
      const error = new Error("File not found");
      setLoading(false);
      setError(error);
      return;
    }
    const getFile = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(url);
        setFile(data);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    getFile();
  }, [url]);

  return { file };
}

export default useFile;

import React from "react";
import { Content } from "@ui/components";

function Frame() {
  return (
    <Content verticalCenter>
      <h1 style={{ textAlign: "center" }}>404 Page not found</h1>
    </Content>
  );
}

export default Frame;

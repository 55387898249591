import {
  EXPORT_TYPES,
  PART_NAMES,
  EXTENSIONS,
  DRAWING_TO_3D_ACTIONS,
} from "@consts/common";
import { degToRad } from "@math/number";

const { PRINTING_3D, STEP: EXPORT_TYPE_STEP, WEB, PROTO_3D } = EXPORT_TYPES;
const { FRONT, LENS, TEMPLE } = PART_NAMES;
const { STL, GLB, GLB_DRACO, STEP } = EXTENSIONS;
const {
  FREECAD_GENERATE_STEP,
  FREECAD_GENERATE_STL,
  FREECAD_WELDING,
  BLENDER_BEND,
  BLENDER_DISPLACEMENT,
  BLENDER_GENERATE_STL,
  BLENDER_GENERATE_GLB,
  BLENDER_GENERATE_GLB_DRACO,
  BLENDER_BEND_TEMPLE,
  BLENDER_WELDING,
} = DRAWING_TO_3D_ACTIONS;

const DEBUG = false;
const DRAWING_ROUND_PRECISION = 3;
const MAX_ANGLE_STRAIGHT = degToRad(0.5);
const MIN_ANGLE_EDGE = degToRad(45);
const SEG_LENGTH_INITIAL = 0.1;

const INCIDENCE_OF_VARIATIONS_KEY_NOSE = 0.15;
const MIN_DISTANCE_BETWEEN_OFFSET_POINTS = 0.1;

// between bottom bridge and key nose starting
const RATIO_KEY_NOSE_SMOOTHING = 0.75;
const SMOOTH_LENGTH_BRIDGE = 10;
// Sometimes, the leftest point in the bridge is not the lowest
const BOTTOM_BRIDGE_TOLERANCE = 1;

const MINIMUM_POINTS_CUBIC_BEZIER = 4;

// only for python
// the next two help to fix the problem of the
// small gap between the pads and the bevel section
const KEY_NOSE_OFFSET_MULTIPLICATOR = 0.5;
const BRIDGE_EXTRA_HEIGHT = 4;
const PAD_FIX_AREA_WIDTH_EXTRA = 20;
const PAD_FIX_SAGITTA_CALIBER_EXTRA = 0.05;
const MAX_SIZE = 200;
const PAD_START_Y_REL_TO_BOTTOM = 0;
const PAD_END_X_REL_TO_CALIBER_CENTER = 0;
const HANDLE_CURVATURE_EXTRA_DEPTH_PADS = 1;
/**
 * In pad steps
 * Y=0 means 0 (z of depth)
 * Y=1 means extra
 * Y=-1 means sagitta caliber
 */
const PAD_STEPS = [
  [0, 0],
  [0.2, 1],
  [0.3, 1],
  [0.5, 0],
  [0.85, -1],
  [0.95, -1],
  [1, -1],
];

const ROUND_LENS_SEGMENTS_GROOVE_MAKING = 1;

const THRESHOLD_MOVE_TO_CUT = 0.2;
const FILLET_SMOOTH_LENGTH = 10;
const OFFSET_BEFORE_NORMAL_SPLAY_ANGLE = 1;

const PROTO_WELDING_MIN_OFFSET_Y = 1;
const PROTO_WELDING_RATIO_MALE_FEMALE = 0.4;
const PROTO_WELDING_DEPTH = 10;
const PROTO_WELDING_DEPTH_DIFF = 1;

const WORKING_DIR = "/tmp";
const WORKING_DIR_ZIP = `${WORKING_DIR}/zip`;
const OUTPUT_DIR = "outputs";

const FILLET_STEPS = 1;

const S3 = {
  OUTPUT_DIR,
};

Object.freeze(S3);

const PRECISIONS = [
  {
    exportType: PRINTING_3D,
    fillet: false,
    clFront: 0.4,
    clLens: 2,
    clTemple: 0.4,
    segLengthFinal: 0.5,
  },
  {
    exportType: PRINTING_3D,
    fillet: true,
    clFront: 0.25,
    clLens: 2,
    clTemple: 0.4,
    segLengthFinal: 0.5,
  },
  {
    exportType: EXPORT_TYPE_STEP,
    fillet: false,
    clFront: 1,
    clLens: 2,
    clTemple: undefined,
    segLengthFinal: 0.5,
  },
  {
    exportType: EXPORT_TYPE_STEP,
    fillet: true,
    clFront: 0.5,
    clLens: 2,
    clTemple: undefined,
    segLengthFinal: 0.5,
  },
  {
    exportType: WEB,
    fillet: false,
    clFront: 1,
    clLens: 2,
    clTemple: 1,
    segLengthFinal: 1,
  },
  {
    exportType: WEB,
    fillet: true,
    clFront: 0.25,
    clLens: 2,
    clTemple: 1,
    segLengthFinal: 1,
  },
  {
    exportType: PROTO_3D,
    fillet: false,
    clFront: 0.4,
    clLens: 2,
    clTemple: 0.4,
    segLengthFinal: 0.5,
  },
  {
    exportType: PROTO_3D,
    fillet: true,
    clFront: 0.25,
    clLens: 2,
    clTemple: 0.4,
    segLengthFinal: 0.5,
  },
];

Object.freeze(PRECISIONS);

const EXPORT_STEP_FRONT = { part: FRONT, extension: STEP };
const EXPORT_STL_FRONT = { part: FRONT, extension: STL };
const EXPORT_GLB_FRONT = { part: FRONT, extension: GLB };
const EXPORT_GLB_DRACO_FRONT = {
  part: FRONT,
  extension: GLB_DRACO,
};
const EXPORT_STEP_TEMPLE = { part: TEMPLE, extension: STEP };
const EXPORT_STL_TEMPLE = { part: TEMPLE, extension: STL };
const EXPORT_GLB_TEMPLE = { part: TEMPLE, extension: GLB };
const EXPORT_GLB_DRACO_TEMPLE = {
  part: TEMPLE,
  extension: GLB_DRACO,
};

const EXPORT_GLB_LENS = { part: LENS, extension: GLB };
const EXPORT_GLB_DRACO_LENS = {
  part: LENS,
  extension: GLB_DRACO,
};

const DEFAULT_EXPORTS = [
  EXPORT_GLB_DRACO_FRONT,
  EXPORT_GLB_DRACO_TEMPLE,
  EXPORT_GLB_DRACO_LENS,
];

const OUTPUT_FILES = {};
OUTPUT_FILES[PRINTING_3D] = [
  ...DEFAULT_EXPORTS,
  EXPORT_STL_FRONT,
  EXPORT_STL_TEMPLE,
];
OUTPUT_FILES[EXPORT_TYPE_STEP] = [
  ...DEFAULT_EXPORTS,
  EXPORT_STEP_FRONT,
  EXPORT_STEP_TEMPLE,
];
OUTPUT_FILES[WEB] = [
  ...DEFAULT_EXPORTS,
  EXPORT_GLB_FRONT,
  EXPORT_GLB_TEMPLE,
  EXPORT_GLB_LENS,
];
OUTPUT_FILES[PROTO_3D] = [
  ...DEFAULT_EXPORTS,
  EXPORT_STL_FRONT,
  EXPORT_STL_TEMPLE,
];
Object.freeze(OUTPUT_FILES);

const ACTIONS = {};
ACTIONS[PRINTING_3D] = [
  BLENDER_BEND,
  BLENDER_BEND_TEMPLE,
  BLENDER_DISPLACEMENT,
  BLENDER_GENERATE_STL,
  BLENDER_GENERATE_GLB_DRACO,
];
ACTIONS[EXPORT_TYPE_STEP] = [
  FREECAD_GENERATE_STEP,
  FREECAD_GENERATE_STL,
  BLENDER_GENERATE_GLB_DRACO,
];
ACTIONS[WEB] = [
  BLENDER_BEND,
  BLENDER_BEND_TEMPLE,
  BLENDER_GENERATE_GLB,
  BLENDER_GENERATE_GLB_DRACO,
];
ACTIONS[PROTO_3D] = [
  FREECAD_WELDING,
  BLENDER_BEND,
  BLENDER_BEND_TEMPLE,
  BLENDER_DISPLACEMENT,
  BLENDER_GENERATE_STL,
  BLENDER_GENERATE_GLB_DRACO,
  BLENDER_WELDING,
];
Object.freeze(ACTIONS);

const DRAWING_TO_3D = {
  DEBUG,
  DRAWING_ROUND_PRECISION,
  MAX_ANGLE_STRAIGHT,
  MIN_ANGLE_EDGE,
  SEG_LENGTH_INITIAL,
  MIN_DISTANCE_BETWEEN_OFFSET_POINTS,
  INCIDENCE_OF_VARIATIONS_KEY_NOSE,
  KEY_NOSE_OFFSET_MULTIPLICATOR,
  BRIDGE_EXTRA_HEIGHT,
  PAD_FIX_AREA_WIDTH_EXTRA,
  PAD_FIX_SAGITTA_CALIBER_EXTRA,
  MAX_SIZE,
  PAD_START_Y_REL_TO_BOTTOM,
  PAD_END_X_REL_TO_CALIBER_CENTER,
  HANDLE_CURVATURE_EXTRA_DEPTH_PADS,
  PAD_STEPS,
  WORKING_DIR,
  WORKING_DIR_ZIP,
  OUTPUT_FILES,
  S3,
  ACTIONS,
  RATIO_KEY_NOSE_SMOOTHING,
  SMOOTH_LENGTH_BRIDGE,
  PRECISIONS,
  BOTTOM_BRIDGE_TOLERANCE,
  MINIMUM_POINTS_CUBIC_BEZIER,
  FILLET_SMOOTH_LENGTH,
  OFFSET_BEFORE_NORMAL_SPLAY_ANGLE,
  THRESHOLD_MOVE_TO_CUT,
  ROUND_LENS_SEGMENTS_GROOVE_MAKING,
  PROTO_WELDING_MIN_OFFSET_Y,
  PROTO_WELDING_RATIO_MALE_FEMALE,
  PROTO_WELDING_DEPTH,
  PROTO_WELDING_DEPTH_DIFF,
  FILLET_STEPS,
};

Object.freeze(DRAWING_TO_3D);

export default DRAWING_TO_3D;

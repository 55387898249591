import React, { useState } from "react";
import useCustomer from "../hooks/useCustomer";
import { ErrorContent } from "@ui/error-content";
import { LoadingContent } from "@ui/loading-content";
import { Button, Aside } from "@ui/components";
import { FormattedDate, FormattedMessage } from "react-intl";
import ERRORS from "@friendly-error/messages";
import { STATUS } from "@paddle/const";
import useCancel from "../hooks/useCancel";
import { useNavigate } from "react-router-dom";

const Subscription = () => {
  const navigate = useNavigate();
  const { customer, loading, error } = useCustomer();
  const {
    cancel,
    success: successCancel,
    loading: loadingCancel,
    error: errorCancel,
  } = useCancel();

  if (loading) {
    return <LoadingContent />;
  }

  if (error) {
    return <ErrorContent error={error} />;
  }

  if (successCancel) {
    return navigate(0);
  }

  const { credits, subscription } = customer;

  const onClickUpdate = () => {
    // eslint-disable-next-line no-undef
    Paddle.Checkout.open({ transactionId });
  };

  const onClickCancel = () => {
    cancel();
  };

  const isSubscriptionActive = [
    STATUS.ACTIVE,
    STATUS.PAST_DUE,
    STATUS.TRIALING,
  ].includes(subscription?.status);

  return (
    <>
      <ul>
        <li>
          <FormattedMessage id="REMAINING_CREDITS" />: {credits}
        </li>
        {subscription && (
          <li>
            <FormattedMessage id="STATUS" />:{" "}
            <FormattedMessage id={subscription?.status.toUpperCase()} />
          </li>
        )}
        {isSubscriptionActive && (
          <li>
            <FormattedMessage id="NEXT_BILL_DATE" />:{" "}
            <FormattedDate value={subscription?.nextBillDate} />
          </li>
        )}
      </ul>
      {isSubscriptionActive && (
        <Aside>
          <Button loading={loading} onClick={onClickUpdate}>
            <FormattedMessage id="UPDATE_PAYMENT_METHOD" />
          </Button>
          <Button loading={loadingCancel} onClick={onClickCancel}>
            <FormattedMessage id="CANCEL" />
          </Button>
        </Aside>
      )}
      {errorCancel && <ErrorContent short error={errorCancel} />}
    </>
  );
};

export default Subscription;

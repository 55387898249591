const TOOLS_API = {
  DIR: "tools",
  ROUTES: {
    IMAGE_TO_SVG: "image-to-svg",
    DRAWING_TO_3D: "drawing-to-3d",
    GENERATE_FRAME_LINK: "generate-frame-link",
  },
};

Object.freeze(TOOLS_API);

export default TOOLS_API;

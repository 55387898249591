import React from "react";

export const Center = ({ children, style }) => {
  return (
    <div
      style={{
        position: "absolute",
        transform: "translate(-50%, -50%)",
        left: "50%",
        top: "50%",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

import React, { createContext, useContext, useState } from "react";
import { PopUp } from "@ui/pop-up";
import { PopUpError } from "@ui/components";

const PopUpContext = createContext();

export const PopUpProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [popUpContent, setPopUpContent] = useState({});

  const openPopUp = (popUpContent) => {
    setPopUpContent(popUpContent);
    setIsOpen(true);
  };

  const closePopUp = () => {
    setIsOpen(false);
  };

  const { error } = popUpContent;

  return (
    <PopUpContext.Provider
      value={{ isOpen, openPopUp, closePopUp, popUpContent }}
    >
      {children}
      {isOpen && !error && <PopUp {...{ ...popUpContent, closePopUp }} />}
      {isOpen && error && <PopUpError {...{ ...popUpContent, closePopUp }} />}
    </PopUpContext.Provider>
  );
};

export const usePopUp = () => useContext(PopUpContext);

/**
 * @file Index.js.
 * @author Jessy Leite
 */

/**
 * @module @auth/client
 */

import Router from "./src/Router";
import Provider from "./src/Provider";
import useAuthUser from "./src/hooks/useAuthUser";
import UpdatePassword from "./src/UpdatePassword";

export { Router, Provider, useAuthUser, UpdatePassword };

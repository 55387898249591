import React from "react";
import { LabelTitle } from "../LabelTitle/LabelTitle";
import { FieldError } from "../FieldError/FieldError";
import { useField, useFormikContext } from "formik";

export const FieldLabelSelectMultiple = ({ name, options, ...props }) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleClick = async (value) => {
    if (field.value.includes(value)) {
      const newFieldValues = field.value.filter((v) => v !== value);
      setFieldValue(name, newFieldValues);
    } else {
      setFieldValue(name, [...field.value, value]);
    }
  };

  const optionsEl = options.map((option) => {
    const { value, label } = option;
    const selected = field.value.includes(value);
    return (
      <div
        className={selected ? "option option-selected" : "option"}
        onClick={() => handleClick(value)}
        key={value}
      >
        <div>{label}</div>
      </div>
    );
  });

  return (
    <label>
      <LabelTitle name={name} {...props} />
      <FieldError name={name} {...props} />
      <div className="options">{optionsEl}</div>
    </label>
  );
};

/**
 * @file index.js.
 * @author Jessy Leite
 */

/**
 * @module @global/axios
 */

import { init, instance } from "./src/axios";
import consts from "./src/consts";

export { init, instance, consts };

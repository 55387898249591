import React from "react";
import PropTypes from "prop-types";
import { Button } from "../Button/Button";
import { useNavigate } from "react-router-dom";

export const NavLinkButton = ({ children, to, external, ...props }) => {
  let onClick;
  if (external) {
    onClick = () => window.open(to);
  } else {
    onClick = () => navigate(to);
  }

  const navigate = useNavigate();
  return (
    <Button onClick={onClick} {...props}>
      {children}
    </Button>
  );
};

NavLinkButton.propTypes = {
  to: PropTypes.string.isRequired,
};

import { useState, useEffect } from "react";
import agent from "../agent";

const usePlans = () => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [plans, setPlans] = useState();

  useEffect(() => {
    const getPlans = async () => {
      try {
        setLoading(true);
        const { data } = await agent.plans();
        const { plans } = data;
        setPlans(plans);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    getPlans();
  }, []);

  return { plans, loading, error };
};

export default usePlans;

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Nav } from "../Nav/Nav";

export const Pagination = ({ offset, limit, count }) => {
  const links = [];

  if (count < limit) {
    return null;
  }

  if (offset >= limit) {
    links.push(
      ...[
        {
          title: <FormattedMessage id="FIRST" />,
          to: `?offset=0&limit=${limit}`,
        },
        {
          title: <FormattedMessage id="PREVIOUS" />,
          to: `?offset=${offset - limit}&limit=${limit}`,
        },
      ]
    );
  }

  if (offset + limit < count) {
    links.push({
      title: <FormattedMessage id="NEXT" />,
      to: `?offset=${offset + limit}&limit=${limit}`,
      right: true,
    });
  }

  return <Nav className="pagination" links={links} />;
};

Pagination.propTypes = {
  offset: PropTypes.number,
  limit: PropTypes.number,
  count: PropTypes.number,
};

const NAMING = {
  FRAME: "frame",
  POCKETS: "pockets",
  PROFILE: "profile",
  FRONT_SLICE: "front-slice",
  IMAGE: "image",
  IMAGE_SANITIZED: "image-sanitized",
  DISPLACEMENT_MAP: "displacement-map",
};

Object.freeze(NAMING);

export default NAMING;

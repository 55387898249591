import { useState, useEffect } from "react";

function useOne({ collection, id }) {
  const { agent, name, defaultValues } = collection;
  const [one, setOne] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (!id) {
      setLoading(false);
      const error = new Error(`No id provided for ${name} one`);
      console.error(error);
      return;
    }
    if (id === "new") {
      setOne(defaultValues);
      setLoading(false);
      return;
    }
    const getOne = async () => {
      try {
        setError(null);
        setLoading(true);
        const { data } = await agent.one(id);
        const { one } = data;
        setOne(one);
      } catch (error) {
        console.error(error);
        console.error(new Error(`Error while loading ${name} one`));
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    getOne();
  }, [agent, name, defaultValues, id]);

  return { loading, error, one };
}

export default useOne;

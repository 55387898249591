/**
 * One (short) from Tartarus collection
 *
 * @typedef {Object} One
 * @property {string} _id
 * @property {string} name
 */

/**
 * Option
 *
 * @typedef {Object} Option
 * @property {string} value
 * @property {string} label
 */

/**
 *
 * @param {One[]} list
 * @returns {Option[]}
 * @example mongoToOptions([_id: "a", name:"AAA"])
 */
const mongoToOptions = (list) =>
  list.map((option) => ({
    value: option._id,
    label: option.name,
  }));

export default mongoToOptions;

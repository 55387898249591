import React from "react";
import PropTypes from "prop-types";
import { Loading } from "../..";

export const IconButton = ({
  type,
  loading,
  children,
  callToAction = false,
  filled = false,
  disabled = false,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={[
        "icon-button",
        filled ? "icon-button-filled" : "",
        callToAction ? "button-call-to-action" : "",
      ].join(" ")}
      onClick={onClick}
    >
      {loading ? <Loading /> : children}
    </button>
  );
};

IconButton.propTypes = {
  type: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

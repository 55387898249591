import React from "react";
import styled from "styled-components";

const COMPLETED = "completed";

const MainContainer = styled.div`
  width: 100%;
  padding-bottom: 1.7rem;
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  :before {
    content: "";
    position: absolute;
    background: var(--primary-color);
    height: 0.3rem;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: "";
    position: absolute;
    background: var(--purple);
    height: 0.3rem;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StepStyle = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 0.3rem solid
    ${({ step }) =>
      step === COMPLETED ? "var(--purple)" : "var(--primary-color)"};
  background-color: ${({ step }) =>
    step === COMPLETED ? "var(--purple)" : "var(--primary-color)"};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepCount = styled.span`
  font-size: 1.5rem;
  color: var(--background-color);
`;

const StepsLabelContainer = styled.div`
  position: absolute;
  top: 4.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StepLabel = styled.span`
  font-size: 1.15rem;
  color: ${({ step }) =>
    step === COMPLETED ? "var(--purple)" : "var(--primary-color)"};
  text-transform: capitalize;
`;

const CheckMark = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: var(--background-color);
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`;

export const ProgressStepper = ({ steps, activeStepId }) => {
  const totalSteps = steps.length;

  const width = `${(100 / (totalSteps - 1)) * activeStepId}%`;

  return (
    <MainContainer>
      <StepContainer width={width}>
        {steps.map(({ label }, i) => (
          <>
            <StepWrapper key={i}>
              <StepStyle step={activeStepId >= i ? COMPLETED : ""}>
                {activeStepId > i ? (
                  <CheckMark>L</CheckMark>
                ) : (
                  <StepCount>{i + 1}</StepCount>
                )}
              </StepStyle>
              <StepsLabelContainer>
                <StepLabel step={activeStepId >= i ? COMPLETED : ""} key={i}>
                  {label}
                </StepLabel>
              </StepsLabelContainer>
            </StepWrapper>
          </>
        ))}
      </StepContainer>
    </MainContainer>
  );
};

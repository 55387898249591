/**
 * @file 404.js.
 * @author Jessy Leite
 */

/**
 * @module @frontxtemple/server-errors
 */

/**
 * @constant
 * @type {string}
 * @default
 */
const USER_NOT_FOUND = "USER_NOT_FOUND";

/**
 * @constant
 * @type {string}
 * @default
 */
const NOT_FOUND = "NOT_FOUND";

export { NOT_FOUND, USER_NOT_FOUND };

import FILE from "./src/FILE.js";
import SERVER from "./src/SERVER.js";
import COLLECTION_NAMES from "./src/COLLECTION_NAMES.js";
import PART_NAMES from "./src/PART_NAMES.js";
import MATERIAL_MAP_NAMES from "./src/MATERIAL_MAP_NAMES.js";
import PLATFORM_NAMES from "./src/PLATFORM_NAMES.js";
import PAGINATION from "./src/PAGINATION.js";
import CATMUL_LINE_PART from "./src/CATMUL_LINE_PART.js";
import EXTENSIONS from "./src/EXTENSIONS.js";
import EXPORT_TYPES from "./src/EXPORT_TYPES.js";
import NAMING from "./src/NAMING.js";
import SVG_PATH from "./src/SVG_PATH.js";
import S3 from "./src/S3.js";
import DRAWING_TO_3D_ACTIONS from "./src/DRAWING_TO_3D_ACTIONS.js";
import GA_CATEGORIES from "./src/GA_CATEGORIES.js";
import GA_ACTIONS from "./src/GA_ACTIONS.js";
import SIDES from "./src/SIDES.js";
import DIVISIONS from "./src/DIVISIONS.js";
import FILLETS from "./src/FILLETS.js";
import SPLAY_STARTS from "./src/SPLAY_STARTS.js";
import UNITS from "./src/UNITS.js";
import FRAME_MATERIAL_CATEGORIES from "./src/FRAME_MATERIAL_CATEGORIES.js";
import LENS_MATERIAL_CATEGORIES from "./src/LENS_MATERIAL_CATEGORIES.js";

const DEFAULT = {
  FILE,
  SERVER,
  COLLECTION_NAMES,
  PART_NAMES,
  MATERIAL_MAP_NAMES,
  PLATFORM_NAMES,
  PAGINATION,
  CATMUL_LINE_PART,
  EXTENSIONS,
  EXPORT_TYPES,
  NAMING,
  SVG_PATH,
  S3,
  DRAWING_TO_3D_ACTIONS,
  GA_CATEGORIES,
  GA_ACTIONS,
  SIDES,
  DIVISIONS,
  FILLETS,
  SPLAY_STARTS,
  UNITS,
  FRAME_MATERIAL_CATEGORIES,
  LENS_MATERIAL_CATEGORIES,
};

export {
  FILE,
  SERVER,
  COLLECTION_NAMES,
  PART_NAMES,
  MATERIAL_MAP_NAMES,
  PLATFORM_NAMES,
  PAGINATION,
  CATMUL_LINE_PART,
  EXTENSIONS,
  EXPORT_TYPES,
  NAMING,
  SVG_PATH,
  S3,
  DRAWING_TO_3D_ACTIONS,
  GA_CATEGORIES,
  GA_ACTIONS,
  SIDES,
  DIVISIONS,
  FILLETS,
  SPLAY_STARTS,
  UNITS,
  FRAME_MATERIAL_CATEGORIES,
  LENS_MATERIAL_CATEGORIES,
  DEFAULT as default,
};

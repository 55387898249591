import React from "react";
import PropTypes from "prop-types";
import { InputDragFile } from "../..";

const InputDragFileImage = (props) => (
  <InputDragFile
    {...props}
    accept={{
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [],
    }}
  />
);

InputDragFileImage.propTypes = {
  name: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export { InputDragFileImage };

import React from "react";

export const Hideable = ({ children, hiden, style }) => {
  const styleHiden = hiden ? { display: "none" } : {};

  return (
    <div
      style={{
        ...styleHiden,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import "./nav-hamburger.css";

export const NavHamburger = ({ links }) => {
  const linksEl = [];
  links.forEach((link) => {
    const { title, to } = link;
    linksEl.push(
      <li key={to}>
        <NavLink
          to={to}
          className={({ isActive }) => (isActive ? "nav-link-active" : "")}
        >
          {title}
        </NavLink>
      </li>
    );
  });

  return (
    <nav className="navbar menu menu-hamburger">
      <div className="nav-container">
        <input className="checkbox" type="checkbox" name="" id="" />
        <div className="hamburger-lines">
          <span className="line line1"></span>
          <span className="line line2"></span>
          <span className="line line3"></span>
        </div>
        {/*<div className="logo">
          <h1>Navbar</h1>
  </div>*/}
        <div className="menu-items">{linksEl}</div>
      </div>
    </nav>
  );
};

NavHamburger.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

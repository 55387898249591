import React, { lazy } from "react";
import { Route } from "react-router-dom";

const FrameMaterial = lazy(() => import("./frame-material/FrameMaterial"));
const FrameMaterials = lazy(() => import("./frame-material/FrameMaterials"));

const LensMaterial = lazy(() => import("./lens-material/LensMaterial"));
const LensMaterials = lazy(() => import("./lens-material/LensMaterials"));

const MaterialTag = lazy(() => import("./material-tag/MaterialTag"));
const MaterialTags = lazy(() => import("./material-tag/MaterialTags"));

const Router = (
  <>
    <Route path="frame-materials" element={<FrameMaterials />} />
    <Route path="frame-materials/:id" element={<FrameMaterial />} />

    <Route path="lens-materials" element={<LensMaterials />} />
    <Route path="lens-materials/:id" element={<LensMaterial />} />

    <Route path="material-tags" element={<MaterialTags />} />
    <Route path="material-tags/:id" element={<MaterialTag />} />
  </>
);

export default Router;

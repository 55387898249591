import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { IconButton } from "../IconButton/IconButton";
import { ReactComponent as Back } from "../../../../../assets/svg/back.svg";
import { Button } from "../Button/Button";
import { FormattedMessage } from "react-intl";
import { InnerContent } from "../InnerContent/InnerContent";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0.5rem);
  z-index: 9998;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

const PopUpWrapper = styled.div`
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary-color);
  color: var(--background-color);
  padding: var(--padding-y) var(--padding-x);
  width: 1500px;
  max-width: 80%;
  animation: ${fadeIn} 0.3s ease-in-out;
  border-radius: var(--border-radius);

  @media (max-width: 500px) {
    width: 80%;
  }
`;

const PopUpContent = styled.div`
  > * {
    max-width: 100%;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

const PopUp = ({ title, children, closePopUp }) => {
  return (
    <Overlay>
      <PopUpWrapper>
        <InnerContent>
          <h1>{title}</h1>
        </InnerContent>
        <InnerContent>
          <PopUpContent>{children}</PopUpContent>
        </InnerContent>
        <ButtonContainer>
          <Button callToAction onClick={closePopUp}>
            <FormattedMessage id="OK" />
          </Button>
        </ButtonContainer>
      </PopUpWrapper>
    </Overlay>
  );
};

export { PopUp };

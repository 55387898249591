import { atom } from "recoil";
import { JWT } from "@auth/config";

const { TOKEN } = JWT;

const authUserState = atom({
  key: "authUserState",
  default: null,
});

const loadingAuthUserState = atom({
  key: "loadingAuthUserState",
  default: false,
});

const tokenState = atom({
  key: "tokenState",
  default: window.localStorage.getItem(TOKEN),
});

export { tokenState, authUserState, loadingAuthUserState };

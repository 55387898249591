import { useFormikContext, useField } from "formik";
import React from "react";
import { LabelTitle } from "../LabelTitle/LabelTitle";
import { FieldError } from "../FieldError/FieldError";
import { InputSelect } from "../InputSelect/InputSelect";

export const FieldLabelSelect = ({
  name,
  options,
  inline = true,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const { value } = field;

  let onChange = (value) => setFieldValue(name, value);

  const labelEl = <LabelTitle name={name} {...props} />;
  const fieldEl = <InputSelect {...{ options, value, onChange }} />;

  return (
    <label className={inline ? "inline" : ""}>
      {labelEl}
      {fieldEl}
      <FieldError name={name} {...props} />
    </label>
  );
};

/**
 * @file LOGIN_SCHEMA.js.
 * @author Jessy Leite
 */

/**
 * @module @auth/validators
 */

import * as Yup from "yup";

/**
 * @constant
 * @type {object}
 * @default
 */
const LOGIN_SCHEMA = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

export default LOGIN_SCHEMA;

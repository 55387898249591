/**
 * @file axios.js.
 * @author Jessy Leite
 */

/**
 * @module @global/axios
 */

import axios from "axios";
// axios.defaults.headers.common = {'Authorization': `bearer ${token}`}

let instance;

const init = (params) => {
  if (instance) {
    return instance;
  }
  instance = axios.create(params);

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!error.response) {
        // Network error occurred (no response from server)
        const customError = {
          message: "ERROR_NETWORK",
          originalError: error,
        };
        return Promise.reject(customError);
      }
      // For other types of errors, pass the error along
      return Promise.reject(error);
    }
  );

  return instance;
};

export { init, instance };

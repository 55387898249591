import { useState } from "react";

function useUpdateOne({ collection }) {
  const { agent } = collection;
  const [one, setOne] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const updateOne = async (values) => {
    try {
      setError(null);
      setLoading(true);
      const updated = await agent.updateOne(values);
      setOne(updated.data.one);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { updateOne, loading, error, one };
}

export default useUpdateOne;

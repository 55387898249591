import React from "react";
import PropTypes from "prop-types";
import { Loading } from "../..";

export const Button = ({
  type = "button",
  loading,
  disabled,
  callToAction,
  children,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={callToAction ? "button-call-to-action" : ""}
    >
      {loading ? <Loading /> : children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  Content,
  ErrorContent,
  FieldLabel,
  InnerContent,
  FormContent,
} from "@ui/components";
import { UPDATE_PASSWORD_SCHEMA } from "@auth/validators";
import useUpdatePassword from "./hooks/useUpdatePassword";
import { FormattedMessage } from "react-intl";
import { notify } from "@ui/notificator";

const defaultValues = {
  oldPassword: "",
  newPassword: "",
};

const UpdatePassword = () => {
  const { success, loading, error, updatePassword } = useUpdatePassword();

  useEffect(() => {
    if (!success) return;
    notify("Password updated", "success");
  }, [success]);

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues}
      validationSchema={UPDATE_PASSWORD_SCHEMA}
      onSubmit={updatePassword}
    >
      {() => (
        <Form>
          <InnerContent>
            {error && <ErrorContent error={error} short />}
            <FormContent inline={true}>
              <FieldLabel
                name="oldPassword"
                type="password"
                displayName={<FormattedMessage id="OLD_PASSWORD" />}
              />
              <FieldLabel
                name="newPassword"
                type="password"
                displayName={<FormattedMessage id="NEW_PASSWORD" />}
              />
            </FormContent>
            <InnerContent>
              <Button type="submit" title="Submit" loading={loading}>
                <FormattedMessage id="UPDATE_PASSWORD" />
              </Button>
            </InnerContent>
          </InnerContent>
        </Form>
      )}
    </Formik>
  );
};

export default UpdatePassword;

import React from "react";
import useGA from "./hooks/useGA.js";

const Provider = ({ id }) => {
  useGA(id);

  return <></>;
};

export default Provider;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Aside } from "../..";
import { FormClose } from "grommet-icons";

export const Prompt = ({ onClick, setOnOpen, setIsOpen, icon }) => {
  const [show, setShow] = useState(false);

  const onOpen = (e) => {
    e.stopPropagation();
    setShow(true);
    setIsOpen(true);
  };

  const onConfirm = (e) => {
    onClose(e);
    onClick();
  };

  const onClose = (e) => {
    e.stopPropagation();
    setShow(false);
    setIsOpen(false);
  };

  useEffect(() => {
    setOnOpen(() => onOpen);
  }, []);

  return (
    <>
      {show && (
        <div className="prompt">
          <Aside>
            <IconButton onClick={onConfirm} filled={true} callToAction={true}>
              {icon}
            </IconButton>
            <IconButton filled={true} onClick={onClose}>
              <FormClose />
            </IconButton>
          </Aside>
        </div>
      )}
    </>
  );
};

Prompt.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  setOnOpen: PropTypes.func,
};

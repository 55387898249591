import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const initSentry = ({ dsn, tracesSampleRate = 1 }) => {
  if (!dsn) {
    throw new Error("dsn is required");
  }
  Sentry.init({
    dsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate,
  });
};

export { initSentry };

import * as errors400 from "./src/400.js";
import * as errors401 from "./src/401.js";
import * as errors402 from "./src/402.js";
import * as errors403 from "./src/403.js";
import * as errors404 from "./src/404.js";
import * as errors413 from "./src/413.js";
import * as errors500 from "./src/500.js";

const errors = [];

errors[400] = errors400;
errors[401] = errors401;
errors[402] = errors402;
errors[403] = errors403;
errors[404] = errors404;
errors[413] = errors413;
errors[500] = errors500;

export default errors;

import React from "react";
import { Aside, NavLinkButton } from "@ui/components";
import { FormattedMessage } from "react-intl";

const CollectionListHeader = ({ id }) => {
  return (
    <Aside verticalAlign>
      <h1>
        <FormattedMessage id={id} />
      </h1>
      <NavLinkButton callToAction={true} to="new">
        <FormattedMessage id="NEW" />
      </NavLinkButton>
    </Aside>
  );
};

export default CollectionListHeader;

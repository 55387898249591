const PART_NAMES = {
  ALL: "all",
  FRONT: "front",
  TEMPLE: "temple",
  LENS: "lens",
  RIVET: "rivet",
};

Object.freeze(PART_NAMES);

export default PART_NAMES;

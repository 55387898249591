import React from "react";
import PropTypes from "prop-types";
import { InputDragFile } from "../..";

const InputDragFileDrawing = (props) => (
  <InputDragFile
    {...props}
    accept={{
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [],
      "application/dxf": [".dxf"],
      "image/vnd.dxf": [".dxf"],
      "image/svg+xml": [".svg"],
    }}
  />
);

InputDragFileDrawing.propTypes = {
  name: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export { InputDragFileDrawing };

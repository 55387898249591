import { FormContent } from "@ui/components";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";

const Tooltip = styled.div`
  position: absolute;
  top: 50%;
  left: ${(props) => (props.isLast ? "unset" : "50%")};
  right: ${(props) => (props.isLast ? "50%" : "unset")};
  background-color: var(--primary-color);
  color: var(--background-color);
  padding: 0.1rem;
  border-radius: 4px;
  opacity: ${(props) => (props.visible ? "1" : "0")};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};

  transition: ease-in-out;
  transition-property: opacity;
  transition-duration: 0.4s;

  z-index: 1;
  pointer-events: none;
`;

const MaterialContainer = styled.div`
  margin-top: calc(var(--padding-y) / 2);
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: left;

  > *:not(:last-child) {
    margin-right: calc(var(--padding-x) / 2);
  }

  padding-bottom: calc(var(--padding-x) / 2);
`;

const MaterialItem = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 3.5rem;
  height: 3.5rem;

  &:hover ${Tooltip} {
    display: block;
  }
`;

const MaterialImage = styled.div`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-image: ${(props) =>
    props.imageUrl ? `url(${props.imageUrl})` : `none`};
  background-size: cover;
  background-position: center;
  background-color: ${(props) =>
    props.imageUrl ? `transparent` : props.color};

  border-width: 2px;
  border-style: solid;
  border-color: transparent;

  transition: ease-in-out;
  transition-property: border;
  transition-duration: 0.3s;

  border-color: ${(props) =>
    props.isSelected ? "var(--primary-color)" : "transparent"};
`;

const includesText = (text, searchTerm) => {
  if (!text) {
    return false;
  }
  if (!searchTerm) {
    return true;
  }
  return text.toLowerCase().includes(searchTerm.toLowerCase());
};

const includesMaterialTags = (materialTags, searchTerm) => {
  return includesText(materialTags.join(", "), searchTerm);
};

const SelectMaterial = ({ materials, setMaterial, defaultMaterial }) => {
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState();
  const [hoveredMaterial, setHoveredMaterial] = useState(null);

  useEffect(() => {
    if (!defaultMaterial) {
      return;
    }
    setSelectedMaterial(defaultMaterial);
  }, [defaultMaterial]);

  useEffect(() => {
    if (!selectedMaterial) {
      return;
    }
    setMaterial(selectedMaterial);
  }, [selectedMaterial]);

  const handleMouseEnter = (materialId) => {
    setHoveredMaterial(materialId);
  };

  const handleMouseLeave = () => {
    setHoveredMaterial(null);
  };

  const handleMaterialClick = (material) => {
    setSelectedMaterial(material);
  };

  // Filter materials based on search input
  const filteredMaterials = materials.filter(
    (material) =>
      includesText(material.name, searchTerm) ||
      includesText(material.ref, searchTerm) ||
      includesText(material.category, searchTerm) ||
      includesMaterialTags(material.materialTags, searchTerm)
  );

  const materialsEl = [];
  const lastIndex = filteredMaterials.length - 1;

  for (let i = 0; i < filteredMaterials.length; i++) {
    const material = filteredMaterials[i];

    materialsEl.push(
      <MaterialItem
        key={material._id}
        onMouseEnter={() => handleMouseEnter(material._id)}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleMaterialClick(material)}
      >
        <MaterialImage
          imageUrl={material?.properties?.maps?.diffuse?.files?.texture?.url}
          color={material.properties.color}
          isSelected={selectedMaterial?._id === material._id}
        />
        <Tooltip
          visible={hoveredMaterial === material._id}
          isLast={i === lastIndex}
        >
          <div>{material.name}</div>
          <div>{material.ref}</div>
        </Tooltip>
      </MaterialItem>
    );
  }

  return (
    <>
      <label>
        <input
          type="text"
          placeholder={intl.formatMessage({ id: "SEARCH_MATERIALS" })}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </label>
      <MaterialContainer>{materialsEl}</MaterialContainer>
    </>
  );
};

export default SelectMaterial;

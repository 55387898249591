import { PAGINATION } from "@consts/common";

const { OFFSET, LIMIT } = PAGINATION;

const createPagination = (enabled = true, offset = OFFSET, limit = LIMIT) => {
  return {
    enabled,
    offset,
    limit,
  };
};

export default createPagination;

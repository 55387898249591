const dictToOptions = (dict) => {
  return Object.keys(dict).map((key) => {
    const value = dict[key];
    return {
      label: value,
      value,
    };
  });
};

export default dictToOptions;

import { Field } from "formik";
import React from "react";
import "./switch.css";
import { LabelTitle } from "../LabelTitle/LabelTitle";
import { FieldError } from "../FieldError/FieldError";

export const FieldLabelSwitch = ({ name, inline = true, ...props }) => {
  const labelEl = <LabelTitle name={name} {...props} />;

  const fieldEl = (
    <div className="switch">
      <Field type="checkbox" name={name} />
      <div className="slider round"></div>
    </div>
  );

  if (inline) {
    return (
      <label className="inline">
        {labelEl}
        <div className="switch-container">{fieldEl}</div>
        <FieldError name={name} {...props} />
      </label>
    );
  } else {
    return (
      <label>
        {labelEl}
        {fieldEl}
        <FieldError name={name} {...props} />
      </label>
    );
  }
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { LoadingContent } from "../..";
import { ErrorContent } from "../..";
import { IconButtonPrompt } from "../..";
import { notify } from "@ui/notificator";
import TableCell from "./TableCell";
import { useRemoveOne } from "@collection/client";
import { FormTrash } from "grommet-icons";

export const Table = ({
  collection,
  head,
  documents,
  loading,
  error,
  clickableRow,
  onClickRow = () => {},
  deletable = false,
}) => {
  const {
    loading: removing,
    error: removeError,
    removed,
    removeOne,
  } = useRemoveOne({
    collection,
  });

  useEffect(() => {
    if (!removeError) {
      return;
    }
    notify(`Error while removing a document`);
  });

  useEffect(() => {
    if (!removed) {
      return;
    }
    setDocs(docs.filter((doc) => doc._id !== removed._id));
  }, [removed]);

  const keys = _.map(head, "key");
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    if (!documents) {
      return;
    }
    setDocs(documents.map((doc) => _.pick(doc, keys)));
  }, [documents]);

  const onRemove = (doc) => {
    removeOne(doc._id);
  };

  if (loading) {
    return <LoadingContent />;
  }

  if (error) {
    return <ErrorContent error={error} short />;
  }

  return (
    <div style={{ overflowX: "auto" }}>
      <table>
        <thead>
          <tr>
            {head.map((_head) => (
              <th key={_head.key}>{_head.name}</th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {docs.map((doc) => (
            <tr
              key={doc._id}
              className={clickableRow ? "tr-link" : ""}
              onClick={() => onClickRow(doc)}
            >
              {keys.map((key) => (
                <TableCell key={key} docKey={key} doc={doc} head={head} />
              ))}
              {deletable && (
                <td className="delete">
                  {
                    <IconButtonPrompt
                      loading={removing}
                      onClick={() => onRemove(doc)}
                    >
                      <FormTrash />
                    </IconButtonPrompt>
                  }
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  collection: PropTypes.object,
  head: PropTypes.arrayOf(PropTypes.object),
  documents: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  error: PropTypes.object,
};

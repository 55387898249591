/**
 * @file Login.js.
 * @author Jessy Leite
 */

/**
 * @module @auth/client
 */

import React, { lazy } from "react";
import { Route } from "react-router-dom";

const Login = lazy(() => import("./Login"));
const Signup = lazy(() => import("./Signup"));
const Logout = lazy(() => import("./Logout"));
const FORGOTTEN_PASSWORD = lazy(() => import("./ForgottenPassword"));

/**.
 * Add login and signup to your Routes
 *
 * @constant
 * @type {React.element}
 * @default
 */
const Router = (
  <>
    <Route path="login" element={<Login />} />
    <Route path="signup" element={<Signup />} />
    <Route path="logout" element={<Logout />} />
    <Route path="forgotten-password" element={<FORGOTTEN_PASSWORD />} />
  </>
);

export default Router;

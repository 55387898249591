import COLLECTION_NAMES from "./COLLECTION_NAMES.js";

const GA_CATEGORIES = {
  FRAME_3D: "frame_3d",
  ...COLLECTION_NAMES,
};

Object.freeze(GA_CATEGORIES);

export default GA_CATEGORIES;

import React from "react";
import { NavSide } from "../..";
import { Content } from "../..";

import PropTypes from "prop-types";

const HeaderSide = ({ links = [] }) => {
  return (
    <header className="side-header">
      <Content>
        <NavSide links={links} />
      </Content>
    </header>
  );
};

HeaderSide.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

export { HeaderSide };

import React, { lazy } from "react";
import { Route, Navigate } from "react-router-dom";
import { Router as MaterialsRouter } from "@materials/client";
import { Router as PaddleRouter } from "@paddle/client";

const Dashboard = lazy(() => import("./Dashboard"));

const Home = lazy(() => import("./home/Home"));

const Frame = lazy(() => import("./frame/Frame"));
const NewFrame = lazy(() => import("./frame/NewFrame"));
const Frames = lazy(() => import("./frame/Frames"));

const FrameTag = lazy(() => import("./frame-tag/FrameTag"));
const FrameTags = lazy(() => import("./frame-tag/FrameTags"));

const Profile = lazy(() => import("./profile/Profile"));
const Profiles = lazy(() => import("./profile/Profiles"));

const FirstTime = lazy(() => import("./help/FirstTime"));

const MyAccount = lazy(() => import("./my-account/MyAccount"));

/*const UserService = lazy(() => import("./user-service/UserService"));
const UserServices = lazy(() => import("./user-service/UserServices"));*/

const Router = (
  <>
    <Route path="/dashboard" element={<Dashboard />}>
      {/*<Route path="user-services" element={<UserServices />} />
    <Route path="user-services/:id" element={<UserService />} />*/}

      <Route path="" element={<Home />} />

      <Route path="frames" element={<Frames />} />
      <Route path="frames/:id" element={<Frame />} />
      <Route path="frames/new" element={<NewFrame />} />

      <Route path="frame-tags" element={<FrameTags />} />
      <Route path="frame-tags/:id" element={<FrameTag />} />

      <Route path="profiles" element={<Profiles />} />
      <Route path="profiles/:id" element={<Profile />} />

      <Route path="first-time" element={<FirstTime />} />
      {MaterialsRouter}
    </Route>
    <Route path="/my-account" element={<MyAccount />} />
    {PaddleRouter}
  </>
);

export default Router;

const COLLECTION_NAMES = {
  FRAME: "frame",
  FRAME_TAG: "frame-tag",
  MATERIAL: "material",
  FRAME_MATERIAL: "frame-material",
  LENS_MATERIAL: "lens-material",
  MATERIAL_TAG: "material-tag",
  USER_SERVICE: "user-service",
  PROFILE: "profile",
};

Object.freeze(COLLECTION_NAMES);

export default COLLECTION_NAMES;

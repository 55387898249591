import ENV from "./ENV.js";

const { USE_LOCAL_BASE, USE_LOCAL_TOOLS, USE_LOCAL_CLIENT } = ENV;

const BASE_URL_LOCAL = "http://localhost:8080";
const BASE_URL_REMOTE = "https://api.frameup.ai";

const BASE_URL = USE_LOCAL_BASE ? BASE_URL_LOCAL : BASE_URL_REMOTE;

const TOOLS_URL_LOCAL = "http://localhost:8081";
const TOOLS_URL_REMOTE = "http://54.155.68.66:8081";

const TOOLS_URL = USE_LOCAL_TOOLS ? TOOLS_URL_LOCAL : TOOLS_URL_REMOTE;

const IMAGE_TO_SVG_URL_LOCAL = "http://localhost:8081";
const IMAGE_TO_SVG_URL_REMOTE = "http://54.155.68.66:8081";

const IMAGE_TO_SVG_URL = USE_LOCAL_TOOLS
  ? IMAGE_TO_SVG_URL_LOCAL
  : IMAGE_TO_SVG_URL_REMOTE;

const CORS_ORIGIN_REMOTE = [
  "https://frameup.ai",
  "https://frameup.ai/",
  "https://www.frameup.ai",
  "https://www.frameup.ai/",
];
const CORS_ORIGIN_LOCAL = ["http://localhost:3000"];

const CORS_ORIGIN = USE_LOCAL_CLIENT ? CORS_ORIGIN_LOCAL : CORS_ORIGIN_REMOTE;

const REMOTE_URL = {
  BASE_URL,
  TOOLS_URL,
  IMAGE_TO_SVG_URL,
  CORS_ORIGIN,
};

Object.freeze(REMOTE_URL);

export default REMOTE_URL;

const NO_HEADERS_AUTHORIZATION = {
  headers: { Authorization: " " },
};

Object.freeze(NO_HEADERS_AUTHORIZATION);

const consts = {
  NO_HEADERS_AUTHORIZATION,
};

Object.freeze(consts);

export default consts;

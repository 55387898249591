import ENV from "./ENV.js";

const { IS_DOCKER } = ENV;

const FREECAD_CMD = "FreeCAD_0.21.1-Linux-x86_64.AppImage freecadcmd";
const BLENDER_CMD = "blender";

const PYTHON_DIR_LOCAL = "python";
const PYTHON_DIR_DOCKER = "app/python";
const PYTHON_DIR = IS_DOCKER ? PYTHON_DIR_DOCKER : PYTHON_DIR_LOCAL;

const DXF_TO_SVG_PY = "dxfToSvg.py";
const FRONT_TO_STEP_PY = "frontToStep.py";
const FULL_FRONT_TO_STEP_PY = "fullFrontToStep.py";
const LENS_TO_STEP_PY = "lensToStep.py";
const TEMPLE_TO_STEP_PY = "templeToStep.py";
const FXT3_TO_STL_PY = "fxt3ToStl.py";
const REUNITE_SEGMENTS_PY = "reuniteSegments.py";
const BLENDER_PY = "blender.py";
const BLENDER_FRONT_PY = "blenderFront.py";
const BLENDER_LENS_PY = "blenderLens.py";
const BLENDER_TEMPLE_PY = "blenderTemple.py";

const PYTHON = {
  FREECAD_CMD,
  BLENDER_CMD,
  PYTHON_DIR,
  FRONT_TO_STEP_PY,
  FULL_FRONT_TO_STEP_PY,
  LENS_TO_STEP_PY,
  TEMPLE_TO_STEP_PY,
  FXT3_TO_STL_PY,
  REUNITE_SEGMENTS_PY,
  BLENDER_PY,
  BLENDER_FRONT_PY,
  BLENDER_LENS_PY,
  BLENDER_TEMPLE_PY,
  DXF_TO_SVG_PY,
};

Object.freeze(PYTHON);

export default PYTHON;

import { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import agent from "../agent";
import { authUserState, loadingAuthUserState, tokenState } from "../states";

const useAuthUser = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useRecoilState(loadingAuthUserState);
  const [authUser, setAuthUser] = useRecoilState(authUserState);
  const token = useRecoilValue(tokenState);

  useEffect(() => {
    if (loading || authUser || !token) {
      return;
    }
    const getAuthUser = async () => {
      try {
        setLoading(true);
        const { data } = await agent.authUser();
        const { user } = data;
        setAuthUser(user);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    getAuthUser();
  }, []);

  return { authUser, loading, error };
};

export default useAuthUser;

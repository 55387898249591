import { instance } from "@global/axios";
import urlJoin from "url-join";
import { getFullPath, findCollection } from "@collection/common";

const createGenericAgent = (collectionClient, collectionName) => {
  const collection = findCollection(collectionClient, collectionName);
  const fullPath = getFullPath(collection, { isProtected: true });

  return {
    list: (offset, limit, short = false, filters = {}) =>
      instance.get(fullPath, {
        params: {
          offset,
          limit,
          short,
          ...filters,
        },
      }),
    one: (id) => instance.get(urlJoin(fullPath, id)),
    updateOne: (values) =>
      instance.post(urlJoin(fullPath, "update-one"), values),
    removeOne: (id) => instance.delete(urlJoin(fullPath, "remove-one", id)),
  };
};

export default createGenericAgent;
